.users-form, .users-novy-form, .zapomenute-heslo {

	td {
		padding-bottom: 5px;
	}

	.forms-levy, .c {
		width: 140px;
		padding-right: 12px;
	}
	
}

.users-form {

}

.zapomenute-heslo table {
	margin: 10px 0;
}

.users-input {
	border: solid 1px #ccc;
	border-radius: 3px;
	transition: all 0.3s;
	color: @pageTextColor;
	box-sizing: border-box;
	height: 32px;
	width: 100%;
	padding-left: 8px;
	font: normal normal 14px opensans, sans-serif;	

	&:focus {
		border-color: @accentColor;
		border-color: @accentColor;
	}
}

.users-button, a.users-button {
	border: none;
	display: inline-block;
	text-decoration: none;	
	border-radius: 3px;
	font-family: opensans, sans-serif;
	font-size: 16px;
	padding: 5px 20px;
	background-color: @accentColor;
	color: @accentText;
	box-sizing: border-box;
	height: 32px;
}