body {
	font-family: opensans, Arial, sans-serif;
	background: white;
	font-size: 14px;
}

@font-face {
    font-family: 'OpenSans';
    src: url('@{fontsPath}OpenSans-Bold-webfont.eot');
    src: url('@{fontsPath}OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fontsPath}OpenSans-Bold-webfont.woff') format('woff'),
         url('@{fontsPath}OpenSans-Bold-webfont.ttf') format('truetype'),
         url('@{fontsPath}OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSans';
    src: url('@{fontsPath}OpenSans-Italic-webfont.eot');
    src: url('@{fontsPath}OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fontsPath}OpenSans-Italic-webfont.woff') format('woff'),
         url('@{fontsPath}OpenSans-Italic-webfont.ttf') format('truetype'),
         url('@{fontsPath}OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'OpenSans';
    src: url('@{fontsPath}OpenSans-Light-webfont.eot');
    src: url('@{fontsPath}OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fontsPath}OpenSans-Light-webfont.woff') format('woff'),
         url('@{fontsPath}OpenSans-Light-webfont.ttf') format('truetype'),
         url('@{fontsPath}OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: @fontWeightLight;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSans';
    src: url('@{fontsPath}OpenSans-LightItalic-webfont.eot');
    src: url('@{fontsPath}OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fontsPath}OpenSans-LightItalic-webfont.woff') format('woff'),
         url('@{fontsPath}OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('@{fontsPath}OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: @fontWeightLight;
    font-style: italic;

}

@font-face {
    font-family: 'OpenSans';
    src: url('@{fontsPath}OpenSans-Regular-webfont.eot');
    src: url('@{fontsPath}OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fontsPath}OpenSans-Regular-webfont.woff') format('woff'),
         url('@{fontsPath}OpenSans-Regular-webfont.ttf') format('truetype'),
         url('@{fontsPath}OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'OpenSans';
    src: url('@{fontsPath}OpenSans-BoldItalic-webfont.eot');
    src: url('@{fontsPath}OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fontsPath}OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('@{fontsPath}OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('@{fontsPath}OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;

}


@font-face {
    font-family: 'icofont';
    src: url('@{fontsPath}icofont.eot');
    src: url('@{fontsPath}icofont.eot?#iefix') format('embedded-opentype'),
         url('@{fontsPath}icofont.woff') format('woff'),
         url('@{fontsPath}icofont.ttf') format('truetype'),
         url('@{fontsPath}icofont.svg#Ikony_Optimato_eshop_sablona') format('svg');
}



/**

.icon-logo-optimato = Ikona Optimáta
.font-logo-optimato = spešl font Optimáta, pod znakem "o" (malé O) je naše logo

§*/

@font-face {
    font-family: 'Optimato logo';
    src:url('//shared.optimato.cz/imgs_system/mikrologa/@{fontsPath}optimato-logo.eot');
    src:url('//shared.optimato.cz/imgs_system/mikrologa/@{fontsPath}optimato-logo.eot?#iefix') format('embedded-opentype'),
        url('//shared.optimato.cz/imgs_system/mikrologa/@{fontsPath}optimato-logo.ttf') format('truetype'),
        url('//shared.optimato.cz/imgs_system/mikrologa/@{fontsPath}optimato-logo.woff') format('woff'),
        url('//shared.optimato.cz/imgs_system/mikrologa/@{fontsPath}optimato-logo.svg#Optimato logo') format('svg');
    font-weight: normal;
    font-style: normal;
}

.font-logo-optimato {
    font-family: 'Optimato logo';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 16px;
    font-size: 36px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}

.icon-logo-optimato {
    font-family: 'Optimato logo';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 16px;
    text-decoration: none !important;
    font-size: 36px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-logo-optimato:before {
    content: "\6f";
}


