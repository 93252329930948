/**
* @version 1.1
* @changelog 
*     1.1 ladění vzhledu v IE
*/

.swiper {

	margin-top: 20px;

	*[data-swiper-parallax] {
		transform: translateZ(0.1px);
	}

	.swiper-slide {
		position: relative;

		img {
			max-width: 100%;
		}

		a {
			text-decoration: inherit;
		}
	}

	&.with-pagination {
		.swiper-container {
			padding-bottom: 20px;
		}
		.swiper-pagination {
			bottom: 0px;
		}
	}

	&.text-slider {

		.swiper-wrapper {
			align-items: stretch;
		}

		.swiper-slide {
			height: auto;
		}

		.text-base {
			position: absolute;
			left: 0;
			top: 0;
			color: white;
			padding: 20px;
			box-sizing: border-box;
		}

		.title {
			font-size: 24px;
			margin-bottom: 12px;
			margin-top: 5px;
			display: block;

			&:empty {
				display: none; 
			}
		}

		.subtitle {
			font-size: 14px;
			margin-bottom: 12px;
			&:empty {
				display: none; 
			}			
		}

		.button, .text-base>a {
			padding: 8px 16px;
			border-radius: 3px;
			color: white;
			display: inline-block;
			background-color: #1352e2;

			&:empty {
				display: none; 
			}			

			i {
				margin-left: 10px;
				font-size: 14px;
			}
		}

		&.text-slider-darkened .background {
			filter: brightness(40%);
		}
	}

	&.simple-slider {
		.swiper-wrapper {
			align-items: stretch;
		}
		.swiper-slide {
			height: auto;
			// display: flex; // Kvůli IE nemůžeme mít dva flexboxy ihned v sobě
			// flex-direction: column;
		}
		.title {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background-color: rgba(40, 40, 40, 0.7);
			color: white;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 17px;
			padding: 5px @pagePadding;
			text-align: center;
			box-sizing: border-box;
			// flex-grow: 0;
			// flex-shrink: 0;

			&:empty {
				display: none; 
			}

			a[data-swiper-parallax] {
				display: block;
			}

		}

		.image img {
			display: block;
		}
		.stretcher {
			flex-grow: 10;
			flex-shrink: 10;
		}
	}

	.swiper-pagination-bullet {
		opacity: 0.6;
		background-color: #bbb;
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
		background-color: #1352e2;
	}

	&.cube-slider {
		overflow-x: auto; // Kvůli Firefoxu
	}
}