.kosik-tabulka {

	@headerClassSelectorTr: ~'tr.@{orderHeadingClass} td';
	@headerClassSelectorTd: ~'td.@{orderHeadingClass}';
	
	@{headerClassSelectorTr}, @{headerClassSelectorTd} {
		border-bottom: solid 1px @orderHeadingBorderColor;
		padding-bottom: 3px;
		vertical-align: bottom;
		font-size: 12px;
		color: @orderHeadingTextColor;
		text-align: center;
		line-height: normal;		
	}

	td {
		padding-top: 3px;
		padding-bottom: 3px;
		font-size: 13px;
		vertical-align: middle;		

		&:not(:last-child) {
			padding-right: 5px;
		}
	}

	tr.vyraznyt, tr.vyraznyt td {
		background-color: transparent;
	}

	// Záhlaví

	.kosik-tabulka-nadpis-cena1, .kosik-tabulka-nadpis-cena2 {
		min-width: 50px;
	}

	.kosik-tabulka-nadpis-pocet, .kosik-pocet {
		width: 40px;
		text-align: center;		

		input {
			width: 40px;
			box-sizing: border-box;
			border: solid 1px @orderHeadingBorderColor;
			border-radius: 3px;
			padding: 2px 4px;
			text-align: center;
			font-size: 13px;			
		}
	}

	// Řádky produktů

	.kosik-cena-bezdph {
		font-size: 12px;
	}

	.kosik-cena-dph, .kosik-cena-bezdph {
		white-space: nowrap;
	}

	.kosik-radek-produkt td {
		border-bottom: solid 1px @cartProductRowBorderColor;
	}

	.kosik-produkt-nazev a {
		text-decoration: none;
	}

	.kosik-obrazek {
		display: block;
		padding: 4px 0;		

		img {
			border-radius: 3px;
			max-width: 40px;
			max-height: 60px;
		}
	}

	.kosik-likvidator {

		a {
			display: block;			
			padding: 6px 0 6px 6px;
			text-decoration: none;
			font-size: 16px;			
		}
	}

	// Doprava a platba

	.kosik-radek-doprava td, .kosik-radek-platba td {
		border-bottom: solid 1px @cartProductRowBorderColor;
	}

	// Součtové řádky dole

	.kosik-celkemcena-dph {
		font-weight: bold;
		font-size: 15px;
		color: @accentForEditableText;
		white-space: nowrap;
		padding-right: 0;
	}

	.kosik-doprava-zdarma-zbyva, .kosik-platba-zdarma-zbyva {
		div {
			padding: 6px 0 0 28px;
			position: relative;			
			b {
				white-space: nowrap;
			}
			&::before {
				display: block;				
				font-size: 18px;
				content: "\f06a";
				font-family: FontAwesome;
				position: absolute;
				left: 0;
				top: 0;
				top: calc(50% - 10px);
			}
		}
	}

	.kosik-dosazena-platba-zdarma, .kosik-dosazena-doprava-zdarma {
		div {
			padding: 6px 0 0 28px;
			position: relative;
			
			b {
				white-space: nowrap;
			}
			&::before {
				font-size: 18px;
				content: "\f118";
				font-family: FontAwesome;
				margin-right: 7px;
				vertical-align: middle;
				position: absolute;
				left: 0;
				top: 0;
				top: calc(50% - 10px);				
			}
		}
	}

}


#slevove-kupony {
	padding: 5px 12px;
	font-size: 13px;
	background-color: @orderCouponsBackground;

	input {
		height: 23px;
		box-sizing: border-box;
	}

	input[type=text] {
		margin-left: 6px;
		margin-right: 6px;
		border: black;
		background: white;
	}

	input[type=submit] {
		background-color: @accentColor;
		color: @accentText;
		border: none;		
		font-size: 13px;
		font-family: opensans, sans-serif;
	}
}