/**
* @version 1.2
* @changelog
*   1.1 drobne upravy
*	1.2 drobnosti kvuli eshopu
*/

/*
.gallery-grid {

	margin-top: 20px;
	line-height: 0;

	img {
		display: inline-block;
		max-width: 100%;
	}

}
*/

.gallery-swiper {

	margin-bottom: (@pagePadding * 0.75);

	.swiper-container, .swiper-wrapper, .swiper-slide  {
		max-width: 100%;
		max-height: 100%;
	}

	.swiper-container {
		position: relative;
		box-sizing: content-box;		
	}

	&.no-fixed-width {
		.swiper-wrapper {
			height: 100%;
		}

		.swiper-slide {
			width: auto;
			max-width: 150%;

			&:first-child {
				max-width: 100%;
			}
		}

	}


	img {
		display: block;
		max-height: 100%;
		max-width: 100%;
	}

	&.stretched-images {

		.swiper-container {
			height: 100px; // Přepíše Javascript
		}

		.swiper-wrapper {
			align-items: stretch;
			max-height: 100%;
			height: 100px; // Přepíše Javascript
		}

		.swiper-slide {

			/*height: 100%;*/

			img {
				/*height: 100%;*/
			}
		}
	}

	.swiper-container {
		.swiper-pagination {
			bottom: 3px;
		}
	}

	&.with-pagination {

		.swiper-container {
			padding-bottom: 15px;

			.swiper-scrollbar {
				bottom: 11px; // 15 px offset - 4 px height
			}
		}

		.swiper-pagination {
			position: absolute; 
			bottom: -5px;
		}

	}

	.swiper-pagination-bullet {
		background: #ccc;
		opacity: 0.4;

		&.swiper-pagination-bullet-active {
			opacity: 1;
			background-color: #1352e2;
		}
	}

	.title {
		display: block;
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        padding: 4px 12px 4px 12px;
        box-sizing: border-box;
        color: white;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 15px;

	}


	.swiper-container-horizontal > .swiper-scrollbar {
		background: rgba(255, 255, 255, 0.4);
		width: 100%;
		left: 0;
		bottom: 0px;
		border-radius: 0;
		height: 4px;
	}

	.swiper-scrollbar-drag {
		background: rgba(0, 0, 0, 0.8);
		border-radius: 0;
	}

	&.full-width {
		h2, .h2, .heading {
			padding-left: @pagePadding;
			padding-right: @pagePadding;
		}
	}

}
