.link-grid {

	.full-width;
	display: flex;
	flex-wrap: wrap;
	border-top: @linkGridBorder;
	max-width: none;

	margin-top: 20px;
	margin-bottom: 20px;

	a {
		display: inline-block;
		padding: 13px 15px;
		box-sizing: border-box;
		background-color: @linkGridBackground;
		color: @linkGridColor;
		position: relative;
		max-height: 150px;
		text-decoration: none;
		
		border-right: @linkGridBorder;
		border-bottom: @linkGridBorder;
		text-align: center;		

		transition: background 0.3s, color 0.1s;

		&.active, &:active {
			background: @linkGridActiveBackground;
			color: @linkGridActiveColor;
			border-color: @linkGridActiveBorderColor;
		}
	}

	&.no-icons {
		.name {
			padding-top: 0;
		}
	}

	&.no-borders {
		border-top: none;

		a {
			border: none;
		}
	}

	&.grid-3 {
		a {
			width: 33.3%;
			&:nth-child(3n) {
				border-right: none;
			}
		}
	}

	&.grid-2 {
		a {
			width: 50%;
			&:nth-child(2n) {
				border-right: none;
			}			
		}
	}

	&.grid-4 {
		a {
			width: 25%;
			&:nth-child(4n) {
				border-right: none;
			}			
		}
	}

	.name {
		display: block;
		padding-top: 6px;
		text-transform: uppercase;
		line-height: 16px;
		font-size: 12px;
		text-overflow: ellipsis;
	}

	.icon {
		font-size: 28px;

		img {
			max-width: 48px;
			max-height: 28px;
		}
	}

	&.with-images {
		.name {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			box-sizing: border-box;
			padding: 7px 10px;
			background-color: @linkGridLabelBackground;
			color: @linkGridLabelColor;
		}

		a.active .name {
			background: @linkGridActiveBackground;
			color: @linkGridActiveColor;
		}

		a {
			padding: 0;
			transition: none;
			min-height: 30px;

			&.processed-background {
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				min-width: 100px;
				min-height: 0;
			}
		}

		.image img, img.image {
			max-width: 100%;
			max-height: 100%;
		}
	}

}

#content .link-grid {
	max-width: none;
}


.new-vypis {

	.link-grid {

		border: none;
		margin-bottom: 0px !important;
		border-bottom: 1px solid #D2D2D2;

		.item {

			background: none !important;
			display: flex;
			align-items: center;
			border-right: none;
			border-top: none;
			padding: 18px;
			border-bottom: 1px solid #D2D2D2;

			&:last-child {

				border-bottom: 0px;

			}

			.image {

				width: 80px;
				height: 80px;
				display: flex;
				align-items: center;
				border-radius: 50%;
				overflow: hidden;

				img {

					opacity: 1 !important;
					width: 100% !important;
					transform: scale(1.5);

				}

			}

			.name {

				position: relative;
				background: none;
				width: 170px;
				padding: 0;
				margin-left: 20px;
				text-align: left;

			}

		}

	}

}

#produkty-vypis-info {

	.vlevo-info {

		height: 100px;
		width: 90%;
		display: flex;
		justify-content: center;
		font-size: 16px;
		margin: 0 auto;
		text-align: center;
		align-items: center;
		flex-direction: column;

	}

}