/*
* @version 1.1
* @changelog
*   1.1 Opraven problem s sedymi mezerami mezi obrazky
*/

.wide-image-menu {

	margin-top: 20px;
	.full-width;

	a {
		display: block;
		position: relative;
		line-height: 0;

		border-bottom: @wideMenuSeparator;

		&:last-child {
			border-bottom: none;
		}

		.image {
			max-width: 100%;
			max-height: 250px;
			display: block;
			text-align: center;
			overflow: hidden;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.name {
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 6px @pagePadding;
			box-sizing: border-box;
			background-color: @wideMenuLabelBackground;
			color: @wideMenuLabelColor;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 15px;

		}
	}
}