.prehled-objednavky-public {

	@headerClassSelectorTd: ~'td.@{orderHeadingClass}';

	@{headerClassSelectorTd} {
		background: @orderDeliveryHeadingBackground;
		padding: 5px 12px;
		line-height: normal;						
	}

	td {
		padding: 3px 8px;
	}

	.c {
		width: 50%;
	}

}

.prehled-objednavky-public-polozky, .prehled-objednavky-public-events {

	@headerClassSelectorTd: ~'td.@{orderHeadingClass}';

	@{headerClassSelectorTd} {
		background: @orderDeliveryHeadingBackground;
		padding: 5px 12px;
		line-height: normal;						
	}

	td {
		padding: 3px 8px;
	}	

}