.eshop-objednavka-form {

	@headerClassSelectorTr: ~'tr.@{orderHeadingClass} td';
	@headerClassSelectorTrOnly: ~'tr.@{orderHeadingClass}';
	@headerClassSelectorTd: ~'td.@{orderHeadingClass}';
	@headerClassSelectorDiv: ~'.@{orderHeadingClass}';
	
	@{headerClassSelectorTr}, @{headerClassSelectorTd} {
		padding: 6px @pagePadding;
		vertical-align: bottom;
		line-height: normal;
		background-color: @orderFormHeadingBackground;		
		color: @orderFormHeadingText;	
		.full-width;		
		margin-bottom: 7px;
	}

	.objednavka-krok2-table {
		@{headerClassSelectorTd} {
			margin-top: 15px;
		}
	}

	.objednavka-krok2-table, #table_dodaci:not(.inv) {
		&, tbody, tr, td {
			display: block;			
		}	

		.c {
			width: auto;
			padding-bottom: 3px;
		}

		.input {
			width: 100%;
			padding: 4px 8px;
			box-sizing: border-box;
			border: @orderFormInputBorder;
			border-radius: 3px;
			margin-bottom: 7px;
		}

		.objednavka-krok2-skvira {
			display: none;			
		}
	}

	.objednavka-krok2-table {
		.vyraznyt td {

			padding-left: 4px;
			padding-right: 4px;

			&.c {
				color: #D90000;
			}
		}
	}

	.objednavka-krok2-poznamka-table {
		textarea {
			width: 100%;
			padding: 4px 8px;
			box-sizing: border-box;
			border: @orderFormInputBorder;
			border-radius: 3px;
			margin-top: 4px;	
			font-family: opensans, sans-serif;		
			font-size: 14px;
		}

		@{headerClassSelectorTd} {
			display: block;
			.full-width;
			padding-left: @pagePadding;			
			padding-right: @pagePadding;			
		}

	}

	#table_dodaci tr:nth-child(2) {
		display: none;		
	}

	.objednavka-krok2-povinne {
		display: none;		
	}

	#div_dodaci {
		padding-top: 20px;

		a {
			margin-top: -20px;
			display: block;			
		}
	}

	.klikadlo-firemni-udaj td {
		&.tbold {
			font-weight: normal;			
		}
	}

	.objednavka-krok2-profiremni {
		padding-top: 4px;
	}

	.objednavka-krok2-podminky {

		margin-bottom: 15px;

		h2, @{headerClassSelectorDiv} {
			padding: 4px @pagePadding;
			vertical-align: bottom;
			line-height: normal;
			background-color: @orderFormHeadingBackground;		
			color: @orderFormHeadingText;	
			font-size: 14px;
			font-weight: normal;
			margin-top: 15px;			
			margin: 15px 0 0;
			.full-width;
		}

		.eshop_podminky {
			margin-top: 4px;
			padding-top: 10px;
			// border: @orderFormInputBorder;
			//padding: 10px 15px;
			//box-sizing: border-box;
			//border-radius: 3px;
		}
	}

	&>br {
		display: none;		
	}

}

.spodni-buttony-2 {

	padding-bottom: 30px;

	.fr {
		float: none;
		padding-bottom: 10px;

		a, input {
			width: 100%;
			box-sizing: border-box;
			text-align: center;			
		}		
	}

}