
a.kosik-tlacitko-vzad, input.kosik-tlacitko-vzad  {
	display: inline-block;
	text-decoration: none;
	color: #666;

	&::before {
		font-family: FontAwesome;
		content: "\f104";
		margin-right: 5px;
	}
}

a.kosik-tlacitko-vpred, input.kosik-tlacitko-vpred {
	display: inline-block;
	text-decoration: none;
	margin: 8px 0;
	padding: 6px 32px 6px 10px;
	font-size: 17px;
	border-radius: 3px;
	position: relative;
	border: none;

	background-color: @accentColor;
	color: @accentText;

	&::after {
		display: block;
		position: absolute;
		right: 10px;
		top: 4px;
		height: 20px;
		top: calc(50% - 10px);
		font-family: FontAwesome;
		content: "\f054";
		margin-left: 8px;
	}

}

.eshop-registruj-se {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;

	&>div {
		width: 32%;
		background-color: @orderRegisterNowBackground;
		border-radius: 3px;
	}

	a {
		padding: 3px 6px;
		display: block;		
		text-decoration: none;		
	}

	.eshop-registruj-se-main {
		display: block;
		font-size: 12px;
		font-weight: bold;
		line-height: normal;
		padding-bottom: 3px;
	}

	.eshop-registruj-se-label {
		display: block;		
		font-size: 11px;
		line-height: normal;
	}
}