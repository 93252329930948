.mobile-switcher.mobile-version {

	i.fa {
		font-size: 14px;
	}

	// Základní zobrazení - na mobilu nebo po kliku na křížek

	& {
		clear: both;
		margin-top: 20px;
		color: @switcherSmallColor;
	}

	.main {
		text-decoration: none;
		padding: 5px 15px;
		text-align: center;
		display: block;


		.fa {
			margin-right: 12px;
		}
	}

	.collapse {
		display: none; 
	}


	// Mám-li velký mobil, nabídneme switcher zvětšený a fixovaný dole

	&:not(.collapsed) {

		@media (min-width: 761px) {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: 10;

			.fa {
				font-size: 14px;
			}

			.main {
				display: block;
				padding: 5px 45px 5px 15px;
				background-color: @switcherBigBackground;
				color: @switcherBigColor;
				text-decoration: none;
				font-size: 13px;
				text-transform: uppercase;
				text-align: center;

				&:hover {
					background-color: darken(@switcherBigBackground, 10%);
				}

				.fa {
					margin-right: 12px;				
				}
			}

			.collapse {
				display: block;
				text-decoration: none;
				position: absolute;
				right: 0px;
				top: 0;
				padding: 5px 10px;
				color: @switcherBigColor;
			}		
			


			// Mám-li desktop, pořádně to zvětšíme, protože na mobilní verzi asi nechceme být

			@media (min-width: 1000px) {	

				.main {
					padding: 10px 30px;
					font-size: 26px;

					.fa {
						margin-right: 24px;
					}
				}

				.collapse {
					font-size: 28px;
					padding-top: 10px;
				}

				i.fa {
					font-size: 28px;
					margin-right: 24px;
				}

			}

		}

	}

}