.tabulka-doprava {

	@headerClassSelectorTd: ~'td.@{orderHeadingClass}';

	@{headerClassSelectorTd} {
		background: @orderDeliveryHeadingBackground;
		padding: 5px 12px;
		line-height: normal;				
	}

	.popiska_dopravy {
		margin-top: 3px;
		font-size: 12px;
		line-height: normal;
	}

	.radek-vyber-dopravy td {
		padding: 5px 0;

		&:first-child {
			text-align: center;			
		}

		strike {
			text-decoration: line-through;
		}

		.bonus-doprava-zdarma {
			color: @accentForEditableText;
			margin-left: 7px;
		}
	}

	.radek-vyber-dopravy:not(:last-child) td {
		border-bottom: @orderDeliveryBorder;
	}


	&:not(:first-child) {
		margin-top: 20px;
	}

}

.spodni-buttony-1 {

	padding-bottom: 30px;

	.fr {
		float: none;
		padding-bottom: 10px;

		a, input {
			width: 100%;
			box-sizing: border-box;
			text-align: center;			
		}		
	}

}