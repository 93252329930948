/**
* @version 1.2
* @changelog
*   1.1 spacer
*	1.2 tabulky
*/

body {
	line-height: 150%;
}

html, body {
	-webkit-text-size-adjust: 100%;
}

input, button, select {
	font-family: opensans, Arial, sans-serif;
}

strong, b {
      font-weight: bold;
}
em, i {
      font-style: italic;
}
big {
      font-size: 120%;
}
small {
      font-size: 80%;
}
p {
	margin-bottom: 1em; 
}

p:last-child {
	margin-bottom: 0;
}

h1 {
	font: normal @fontWeightLight 28px  opensans, Arial, sans-serif;
	margin: 18px 0 12px 0;
}

h2 {
	font: normal @fontWeightLight 24px  opensans, Arial, sans-serif;
	margin: 20px 0 10px 0;
}

h3 {
	font: normal @fontWeightLight 21px  opensans, Arial, sans-serif;
	margin: 20px 0 10px 0;
}

h4 {
	font: normal normal 18px  opensans, Arial, sans-serif;
	margin: 10px 0 7px 0;
}

#content, .content-block {
	&>h1, &>h2, &>h3, &>h4 {
		&:first-child {
			margin-top: 0;
		}
	}
}


#body-main {
	background: @pageBackground;
	color: @pageTextColor;
	font-size: @pageTextSize;
}

#content {
	padding: 20px @pagePadding;
}

.content-block {
	margin-top: 20px;
}

.page-content {
	* {
		max-width: 100%;
	}
}

.full-width {
	margin-left: (-1 * @pagePadding);
	margin-right: (-1 * @pagePadding);		
	max-width: none !important;
}

img.rounded-image, .rounded-image img {
	border-radius: 1000px;
}

.common-content {
	a {
		text-decoration: underline;
	}
}

.good {
	margin: 10px 0px 20px 0px;

	.good_container {
		margin-bottom: 3px;
	  	padding: 8px 35px 8px 14px;	
	  	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	  	background-color: #fcf8e3;
	  	border: 1px solid #fbeed5;
	  	border-radius: 4px;
	  	line-height: 17px;
	}

	.good_ok {
	  color: #468847;
	  background-color: #dff0d8;
	  border-color: #d6e9c6;
	}

	.good_chyba {
	  color: #b94a48;
	  background-color: #f2dede;
	  border-color: #eed3d7;

	}
}

ul>li {
	margin-left: 20px;
}

.no-spinners {
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
    	appearance: none;
    	margin: 0;
	}	
}

.spacer {
	clear: both;	
	height: @pagePadding;
}

.spacer-small {
	clear: both;	
	height: (@pagePadding * 0.67);
}

.spacer-big {
	clear: both;	
	height: (@pagePadding * 1.5);
}

.content-table-wrapper {

	position: relative;

	.table-wrapper-in {
		overflow: auto;
		max-width: none;
	}

	table, tbody {
		max-width: none;
	}

	&::after, &::before {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: (@pagePadding * 1.5);
		content: "";
		background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
		transition: all 0.3s;
		opacity: 0;
		visibility: hidden;
	}

	&::before {
		right: auto;
		left: 0;
		background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	}

	&.can-scroll-right {
		&::after {
			opacity: 1;
			visibility: visible;
		}
	}

	&.can-scroll-left {
		&::before {
			opacity: 1;
			visibility: visible;
		}
	}

}

.articles-list {

	.articles-list {

		margin: 0 !important;

	}

}