/**
* @version 1.3
* @changelog
*    1.1 mobile IE fixes
*	 1.2 podpora pro produkty
*	 1.3 vyresen problem s deformovanymi obrazky
*/

.articles-list {

	.full-width;
	margin-top: 20px;

	a {
		display: block;
		display: flex;
		justify-content: space-between;
		background-color: @articlesListBackground;
		border-bottom: @articlesListBorder;
		position: relative;
		max-width: 100%;
		max-width: 100vw;
		box-sizing: border-box;
		text-decoration: none;

		padding: 15px @pagePadding;
		transition: background 0.3s, color 0.1s;

		&:first-child {
			border-top: @articlesListBorder;
		}

		// border: solid 1px blue;

		&.active, &:active {
			background: @articlesActiveBackground;
			color: @articlesActiveColor;

			.name, .subtitle, .fulltext, .date {
				color: @articlesActiveColor;				
			}
		}

		&.top-aligned {
			align-items: flex-start;
		}

		&.stretch-aligned {
			align-items: stretch;

			.cont {
				justify-content: space-between;
			}

			.image {
				justify-content: space-between;
			}

		}
	}

	.image {
		width: @articlesListImageSize;
		max-height: (@articlesListImageSize * 1.5);
		margin-right: @pagePadding;
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		img {
			max-height: (@articlesListImageSize * 1.5); // IE fix
			max-width: 100%;
		}

		&.with-text-tags {
			max-height: none;
			justify-content: flex-start;
		}
	}


	a.image-right {

		.image {
			margin-right: 0;
			margin-left: @pagePadding;			
			order: 10;
		}

	}	

	.cont {	
		display: flex;
		flex-direction: column;
		flex-grow: 1;		
		justify-content: center;
		align-items: stretch;

		// border: solid 1px red;
	}

	.name {
		display: block;
		color: @articlesTitleColor;
		font-size: @articlesTitleSize;
	}

	.subtitle {
		display: block;
		color: @articlesSubtitleColor;
		font-size: @articlesSubtitleSize;		
		margin-top: 10px;
	}

	.fulltext {
		margin-top: 10px;
		font-size: 85%;
	}

	.minitext {
		margin-top: 10px;
		font-size: 85%;
		color: @articlesSubtitleColor;
	}

	&.no-borders a {
		border-top: none;
		border-bottom: none;
	}

	.date {
		background: @articlesDateBackground;
		color: @articlesDateColor;
		width: @articlesListImageSize;
		height: @articlesListImageSize;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;	
		flex-shrink: 0;	
		border-radius: 100px;

		.big {
			font-size: 30px;
			margin: 5px 0; 
		}

		.small {
			font-size: 13px;
		}
	}

	.no-articles-empty {
		padding: 0 @pagePadding;
	}

}

.mobile-ie-11 .articles-list {
	.cont {
		width: 50%;
		padding-right: 10px;
		flex-shrink: 1;
	}
}

.new-product-list {

	flex-direction: column !important;
	padding: 0px !important;
	border-bottom: 1px solid #D2D2D2 !important;
	margin-bottom: 20px;

	&:active {

		background: none !important;
		color: black !important;

		.name {

			color: black !important;

		}

	}

	&:hover {

		background: none !important;
		color: black !important;

		.name {

			color: black !important;

		}

	}

	.image {

		width: 100%;
		//height: 247px;

		.image-in {

			display: flex;
			height: 100%;

		}

		img {

			max-height: 100%;

		}

	}

	.cont {

		width: 87%;
		padding: 20px;
		font-weight: bold;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.new-button {

			color: white;
			display: flex;
			align-items: center;
			background: #E20019;
			padding: 5px 20px;
			border-radius: 20px;
			height: 30px;
			text-transform: uppercase;
			margin-left: 20px;


		}

		.name {

			display: flex;
			align-items: center;

		}

	}

	.labelpos {

		left: 0px;

		.label {

			transform: rotate(-45deg) translateY(-54px) !important;
			margin-top: 50px;

		}

	}

}