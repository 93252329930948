#menu-pane {
	background: @menuBackground;
	min-height: 101%;  // To prevent scrolling
	min-height: calc(100% + 1px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	h2 {
		font-size: 22px;
		font-weight: normal;
		padding: (@menuHeaderHeight * 0.2) 30px;
		margin: 0;
		line-height: (@menuHeaderHeight * 0.6);
		text-align: center;
		
		&.with-return-arrows {
			padding-left: 45px;
			padding-right: 45px;
		}

	}

	h2:not(:first-child) {
		padding-top: 20px;
		padding-bottom: 10px;
	}

	h3 {
		font-size: 18px;
		font-weight: normal;
		margin: 0;
		line-height: 24px;
		text-align: center;		
		padding-top: 20px;
		padding-bottom: 10px;

	}	

	.mainmenu {

		margin-bottom: 15px;

		.item {

			position: relative;

			.item-link {
				display: block;
				padding: @menuVertPadding 20px @menuVertPadding 50px;
				line-height: 20px
			}

			.expander {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 50px;
				height: 100%;
				font-size: 21px;
				text-align: center;
				padding-top: (@menuVertPadding);
				box-sizing: border-box;
				transition: transform 0.3s;
				transform-origin: 50% 20px;
				transform: rotate(0deg);

				&::before {
					font-family: FontAwesome;
					content: "\f138";
				}
			}

			&:last-child .item:last-child {
				border-bottom: none;
			}

			&.expanded>.expander {
				transform: rotate(90deg);
				/*
				&::before {
					content: "\f147";
				}
				*/
			}

			&.level1 {
				background: @menuLevel1Background;
				color: @menuLevel1Color;
				border-bottom: @menuLevel1Separator;

				&.expanded {
					background: @menuLevel1ExpandedBackground;
					color: @menuLevel1ExpandedColor;
				}

				&.active {
					background: @menuLevel1ActiveBackground;
					color: @menuLevel1ActiveColor;
				}

				.item-link {
					padding-left: @menuLevel1LeftPadding;
				}

			}

			&.level2 {
				background: @menuLevel2Background;
				color: @menuLevel2Color;
				border-bottom: @menuLevel2Separator;

				&.expanded {
					background: @menuLevel2ExpandedBackground;
					color: @menuLevel2ExpandedColor;
				}
				&.active {
					background: @menuLevel2ActiveBackground;
					color: @menuLevel2ActiveColor;
				}
				.item-link {
					padding-left: @menuLevel2LeftPadding;
				}

			}

			&.level3 {
				background: @menuLevel3Background;
				color: @menuLevel3Color;
				border-bottom: @menuLevel3Separator;
				border-left: @menuLevel3SideBorder;

				&.expanded {
					background: @menuLevel3ExpandedBackground;
					color: @menuLevel3ExpandedColor;
				}
				&.active {
					background: @menuLevel3ActiveBackground;
					color: @menuLevel3ActiveColor;
				}	
				.item-link {
					padding-left: @menuLevel3LeftPadding;
				}						
			}

			&.level4 {
				background: @menuLevel4Background;
				color: @menuLevel4Color;
				border-bottom: @menuLevel4Separator;
				border-left: @menuLevel4SideBorder;

				&.expanded {
					background: @menuLevel4ExpandedBackground;
					color: @menuLevel4ExpandedColor;
				}			
				&.active {
					background: @menuLevel4ActiveBackground;
					color: @menuLevel4ActiveColor;
				}
				.item-link {
					padding-left: @menuLevel4LeftPadding;
				}
			}

		}

		&>.item:last-child {
			border-bottom: none;
		}		

	}

	.languages-switcher {

		display: flex;

		a, .item {
			display: block;
			background-color: @languagesBackground;
			color: @languagesColor;
			text-transform: uppercase;
			text-align: center;
			height: @languagesHeight;
			padding-top: 14px;
			flex-grow: 1;
			box-sizing: border-box;

			&.active, &.on {
				background-color: @languagesActiveBackground;
				border-bottom: 5px solid #b6000d;
			}
		}
	}

	.links {

		margin-bottom: 15px;

		li {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		a {
			display: block;
			padding: 5px 10px 5px 40px;
			position: relative;
			color: @menuLinksColor;

			&.active, &.on {
				color: @menuActiveColor;
				background: @menuActiveBackground;
			}

			&:before {
				content: "\f105";
				font-family: FontAwesome;
				display: block;
				position: absolute;
				left: 20px;
				top: 5px;
			}
		}
	}

	.block {
		margin-bottom: 15px;
		color: @menuTextBlockColor;
		padding: 5px 20px 5px 20px;
	}

	.stretcher {
		flex-grow: 1;
	}
}