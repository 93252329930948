body {

	background: @offCanvasBodyBackground;
	background-size: cover;
	background-size: @offCanvasBodyBackgroundSize;


	&.showing-offcanvas-left {		

		#body-main {
			transform: translateX(85%);			
		}
		#body-left  {
			opacity: 1;
			visibility: visible;
			z-index: 1;
			overflow-y: scroll;
			transform: none;
			-webkit-overflow-scrolling: touch;

			&.transitioning {
				z-index: -1;
			}
		}

		&.pulling-spring {
			#body-main {
				transform: translateX(80%);
				z-index: 4;
			}
		}
	}

	&.showing-offcanvas-right {


		#body-main {
			transform: translateX(-85%);

			&.pull-spring {
				transform: translateX(-80%);
			}			
		}		
		#body-right  {
			opacity: 1;
			visibility: visible;
			z-index: 1;
			overflow-y: scroll;
			transform: none;
			-webkit-overflow-scrolling: touch;
			display: block;

			&.transitioning {
				z-index: -2;
			}
		}
		&.pulling-spring {
			#body-main {
				transform: translateX(-80%);
				z-index: 4;
			}
		}		
	}

	&.showing-offcanvas, &.transitioning {
		overflow: hidden;

		#body-main {
			z-index: 4;
			box-shadow: @offCanvasBodyShadow;
		}

		#body-right {
			display: block;			
		}

		#navbar {
			position: absolute;
		}

		&.android-4 #navbar {
			display: none; 
		}

		&.android-2 {

			overflow: visible;
			height: auto;

			#offcanvas-wrapper {
				overflow: visible;
				height: auto;
			}
			#body-left, #body-right {
				overflow: visible;
				height: auto;
				display: block;
				position: absolute;
				// Bohužel někdy tento fix vůbec nefunguje
			}

		}

	}
}


body.showing-offcanvas {
	#offcanvas-wrapper {
		overflow: hidden;
		width: 100vw;
		height: 100.5vh;
	}
	#body-main {
		filter: brightness(@offCanvasBodyBrightness);
	}	
}

body.unscrollable {
	overflow: hidden;
	overflow-y: hidden;
}

#body-main {
	min-height: 100vh;
	width: 100%;
	transition: transform @animationSpeed, padding (@animationSpeed - 0.1s), filter @animationSpeed;
	padding-top: @navbarHeight;
	position: relative;
}

body.with-search-visible #body-main {
	padding-top: (@navbarHeight + @navbarSearchHeight);
}



#body-left {
	background: @offCanvasPaneBackground;
	z-index: -2;
}

#body-right {
	background-color: @offCanvasPaneBackground;
	z-index: -1;
	left: 15%;	
}

#navbar {
	height: @navbarHeight;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 5;
}

.offcanvas-frame {
	height: 100%;
	width: 85%;
	position: fixed;
	left: 0;
	top: 0;
	visibility: hidden;
	opacity: 0;	
	transition: opacity @animationSpeed, visibility @animationSpeed, transform @animationSpeed;

	&#body-left {
		transform: @animationOriginStateLeft;
	}
	&#body-right {
		transform: @animationOriginStateRight;
	}

	.return-arrow {
		position: absolute;
		top: 0;
		padding: (@contactsHeaderHeight * 0.5 - 10.5px) 10px;
		font-size: 21px;

		&.left {
			left: 0;
			padding-left: (@contactsHeaderHeight * 0.2 + 7px);
		}

		&.right {
			right: 0;
			padding-right: (@contactsHeaderHeight * 0.2 + 7px);
		}
	}
}

#return-icons {
	height: 0;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 5;

	.return-icon {
		text-align: center;
		position: absolute;
		display: block;
		z-index: 5;
		top: 0;
		transition: visibility @animationSpeed, opacity @animationSpeed, transform @animationSpeed;
		visibility: hidden;
		opacity: 0;		
		padding: (8px + (@navbarHeight - 40px) / 2) 10px 12px 10px;
		width: 15%;
		height: (@navbarHeight);
		text-align: center;
		font-size: @navbarIconSize;
		color: @returnIconColor;
		background-color: @returnIconBackground;
		box-sizing: border-box;
	}

	.icon-right {
		right: 0;
		transform: translateX(40px);
	}

	.icon-left {
		left: 0;
		transform: translateX(-40px);
	}
}

body.showing-offcanvas-right #return-icons .icon-left,
body.showing-offcanvas-left #return-icons .icon-right {
	visibility: visible;
	opacity: 1;
	transform: none;
}
