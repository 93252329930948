.kosik-rekapitulace {

	@headerClassSelectorDiv: ~'.@{orderHeadingClass}';

	.sloupec1 {
		float: left;
		width: 49%;
		font-size: 12px;
	}

	.sloupec2 {
		float: right;
		width: 49%;
		font-size: 12px;
	}

	.objednavka-shrnuti-poznamka {
		font-size: 12px;

		&+div+br {
			display: none;			
		}
	}

	@{headerClassSelectorDiv} {
		font-weight: bold;
		font-size: 14px;
	}

	&>div.tbold {
		display: none;		
	}

	&+br, &+br+br {
		display: none;		
	}

	.kosik-tabulka+br {
		display: none;		
	}
}

.spodni-buttony-3 {
	.fr {
		float: none;
		padding-bottom: 10px;

		a, input {
			width: 100%;
			box-sizing: border-box;
			text-align: center;			
		}		
	}
}