/*
CSS reset based on Eric Meyer - http://meyerweb.com/eric/thoughts/2007/05/01/reset-reloaded/
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
dl, dt, dd, ol, ul, li, button, input
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-weight: inherit;
      font-style: inherit;
      font-size: 100%;
      font-family: inherit;
      vertical-align: baseline;
      color: inherit;
      text-decoration: none;
}
:focus {
      outline: 0;
}
body {
      line-height: 1;
      color: black;
      background: white;
}
table {
      border-collapse: separate;
      border-spacing: 0;
}
caption, th, td {
      text-align: left;
      font-weight: normal;
}
blockquote:before, blockquote:after,
q:before, q:after {
      content: "";
}
blockquote, q {
      quotes: "" "";
} 
body {
      input, button {
            -webkit-appearance:none;
      }
      input[type=radio] {
            -webkit-appearance: radio;
      }
      input[type=checkbox] {
            -webkit-appearance: checkbox;
      }      
      
      -webkit-tap-highlight-color: rgba(0,0,0,0);
}

