.tab-container {

	margin-bottom: (@pagePadding * 0.75);

	.tabs {
		display: flex;
		flex-wrap: wrap;

		a {
			display: block;
			flex-grow: 1;
			text-align: center;
			font-size: 14px;
			text-transform: uppercase;
			text-decoration: none;
			padding: 12px 8px;
			border: solid 1px #D6D6D6;
			border-left: none;
			line-height: normal;
			background-color: @pageBackground;

			&:last-child {
				border-right: none;
			}

			&.active {
				border-bottom: solid 2px #1446FD;
			}
		}
	}

	.contents {
		position: relative;		
	}

	.tab-contents {
		padding-top: @pagePadding;
		transition: all 0.4s;

		&.inactive {
			display: none;
		}

		&.appearing {
			opacity: 0;

			&.left {
				transform: translateX(20px);
			}
			&.right {
				transform: translateX(-20px);
			}

		}
	}

	.helper-contents {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		transition: all 0.4s;
		z-index: 10;

		&.left {
			opacity: 0;
			transform: translateX(-20px);
		}

		&.right {
			opacity: 0;
			transform: translateX(20px);
		}
	}

	&.sticky-header.sticked .tabs {

		position: fixed;
		width: 100%;
		left: 0;
		top: @navbarShrinkedHeight;
		z-index: 4;
		margin: auto;
		box-shadow: @navbarShadow;

	}

}
