// Sem si dejte, co hrdlo r���.

.page-content {

  ul, ol {

    list-style-position: inside;
  }
}

#navbar {

  z-index: 50;

}

.zopim {

  display: none;
}


.odsazeni {

  padding: 20px;

}

.swiper {

  margin-top: 0 !important;

}

h1 {

    //padding: 0 20px 0 20px !important;
    font-size: 22px;

}

.articles-list, .content-block, .link-grid {

  margin-top: 0;

}

.hvezdy-obr {

  img {

    width: 100% !important;
    height: auto !important;

  }

}

.content-block {

  position: relative;
}


.price-tag {

  //display: none !important;

}

.label.akce {


  background: red !important;

}

.top {

  display:block;
  width:80px;
  line-height:120%;
  height:60px;
  font-size: 11px;
  padding-top:20px;
  text-align:center;
  border-radius:50%;
  background:#e2001a;
  position:absolute;
  top:-5px;
  color:#FFF;
  left:-5px; 
  z-index: 100;

  strong,.sleva-procent {display: block;}

}

.obrazek-pozadicko  {

  background-image: url("imgs/banner-cz.jpg");
  color: #FFF;
  padding: 20px 25px;
  font-size: 12px;

  .vyrazny {

    color: #FFF;
    font-size: 12px;

  }

  img {

   display: none;

  }

}


.page-index {

  img.rounded-image, .rounded-image img {

    //border-radius: 0;
  }

  /*.articles-list a {

    background: #E2001A;
    padding: 10px 20px;

    &, .name {

      color: #FFF;
    }
  }*/
}

.link-grid.with-images a.processed-background {width:100%;}

.pridat-poptavka {
	border-radius:6px 6px 5px 5px;
	background:url("imgs/detail-produktu.jpg") left bottom repeat-x;
	border-bottom:2px solid #b6000d;
	color:#FFF;
	font-size:13px;
	font-family:open-bold, Arial, sans-serif;
	width:95px;
	padding:8px 0;
	text-align:center;
	line-height:100%;
	display:inline-block;
	text-decoration:none;
	float:right;
	margin-top:6px;
	border-left: none;
	border-right:none;
	border-top: none;
	cursor: pointer;
}

.button-big {padding-left:2% !important; padding-right:2% !important; width:96% !important;}

.ceny-modifikujici {padding:50px 5px 10px 5px;}
.ceny-modifikujici h3 {font-size:16px; font-weight:normal; border-bottom:1px solid #d0d0d0; padding-bottom:20px;}
.ceny-modifikujici h3 .vyrazny {font-weight:bold; color:#e2001a;}

.napsat-dotaz {position:relative; top:-50px; width:30%;}
.bez-modifik {position:static;}

.ceny-modifikujici .parametr-hodnota {border-left:none; border-right:none; padding:0 4px; font-size:14px; color:#000; border-bottom:1px solid #CCC; padding-bottom: 20px;}
.ceny-modifikujici .parametr-hodnota .textik {float:left; width:100%; padding-top:24px;}
.ceny-modifikujici .parametr-hodnota .cena {float:left; color:#e2001a; font-size:18px; font-family:open-bold, Arial, sans-serif; padding-top:20px; margin-left:30px;}  
.ceny-modifikujici .parametr-hodnota .imgs {float:left; margin-top:4px;}
.ceny-modifikujici .parametr-hodnota .pridat-poptavka {cursor:pointer; margin-top:14px;}
.ceny-modifikujici .parametr-hodnota .pridat-poptavka input {margin-right:10px; border:1px solid #CCC;}

.ceny-modifikujici .poptavka-bg-grey {

  display: none;

}

.poptavka-formular {
  background: #6E6E6E none repeat scroll 0 0 !important;
  border:none !important;
  border-radius:5px !important;
  border-bottom-color: #ddd !important;
  color: white !important;
  padding-left:10px !important;
  padding:10px !important;
  font-weight:bold !important;                      
}

.poptavka-formular:hover {
  background:#B6000D !important; 
}

.pridat-poptavka {cursor:pointer !important;}

.ceny-modifikujici .tmensi {margin:5px 0 10px 0;}

.button-big {width:250px; text-transform:none; padding-top:10px; padding-bottom:10px; font-size:16px;}

.poptavka-new {border-top:3px solid #e2001a; background:#eeeeee; padding:0 20px 20px 20px; margin-bottom: 20px;

  .parametr-hodnota {

    background: white;
    margin-bottom: 5px;
    padding: 10px;

    .cena {

      font-weight: bold;

    }

  }

  table {

    tr {

      display: flex;
      flex-direction: column;

      td {

        .input {

          width: 100%;
          height: 30px;
          margin: 10px 0px;
          border: 1px solid #CCC;

          &.poznamka {

            height: 100px;

          }


        }


        .pridat-poptavka {

          margin-top: 20px;

        }

      }

    }

  }

}

.poptavka-new h4 {color:#222222; font-size:18px; margin:20px 0;}

.zrusit-poptavku {float:right; font-size:13px; margin-bottom: 20px; cursor:pointer;}
.zrusit-poptavku img {margin-right:6px; position:relative; top:4px;}
.zrusit-poptavku span {text-decoration:underline;}
.zrusit-poptavku:hover {color:red;}


.poptavka-table input, .poptavka-odeslani input {

  border: 1px solid #ccc;
  padding-top: 3px;
  padding-bottom: 3px;
}

.poptavka-table {

  .poptavka-tr {

    td {

      padding: 5px 0;
    }

    .poptavka-pocet {

      width: 60px !important;

      input {

        width: 60px !important;
      }
    }
  }

  .poptavka-tr-spodni {

    padding-top: 5px;
    width: 100%;

    td {

      padding-top: 15px;
      width: 50%;
    }

    input.button, a.button {

      display: inline-block;
      padding: 5px 10px;
      border: none;
      background: #d31420;
      text-decoration: none;
      color: #FFF;
      font-size: 12px;
      line-height: normal;
      height: auto;
      box-sizing: border-box;
    }

    a.button {

      background-color: #333;
    }

    input.button {

      font-size: 14px;
    }
  }
}

.poptavka-odeslat input {

  display: inline-block;
  padding: 5px 10px;
  border: none;
  background: #d31420;
  text-decoration: none;
  color: #FFF;
  font-size: 12px;
  line-height: normal;
  height: auto;
  box-sizing: border-box;
}

.poptavka-osobni-udaje {

  table, tbody, td, th {

    display: block;
    padding-bottom: 5px;
  }

  input, textarea {

    width: 100%;
  }
}

.poptavka-odeslani {

  margin-top: -20px;
}

.poptavka-odeslat {

  input {

    font-size: 14px;
    font-weight: bold;
  }
}

.greyform .forms-position-wrapper {

  input, textarea {

    max-width: 100% !important;
  }
}

.akcni-nabidka {

  table, tbody, thead, tr, th, td {

    display: inline-block;
    width: 100%;
  }

  td {

    padding: 5px 0;
  }
}

.tvetsi, big {

  font-size: 104%;
}

.buybox .buy-row .button-part button i.cart {

  display: none;
}

body.shrinkable-navbar #navbar .navbar-icon {

  .fa-shopping-cart {

    display: none !important;
  }
}

#navbar .navbar-icon-badge {

  top: calc(50% - 13px);
  right: 3px;
  font-size: 14px;
  height: 26px;
  width: 26px;
  padding: 0;
  line-height: 26px;
}

.hvezdy-obr {width: 100%; overflow: hidden;}


.hvezdy-obr img  {

  height: 220px;
}

.detail-slova-new {

position: absolute;  z-index: 30; color: #FFF; font-size: 20px; padding: 10px 0; line-height: 130%;
  color: black !important;
  padding-top: 100px;
  width:90%;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 11px;
  font-family: 'Open Sans', Arial, sans-serif;

}

.detail-slova-new .vyrazny strong {

     color: black !important;
     font-weight: 900 !important;
     font-size: 16px !important;

  }

.detail-slova-new small {

    font-size: 12px !important;

}


.detail-slova-new strong {

    font-weight: 900;
    font-size: 14px !important;
    line-height: 150%;

  }

.hvezdy-obr-content,.akcni-srpen2018 {

  position: relative;
  left: -25px;
  top: -20px;
}


.detail-slova-new-content .vyrazny strong {

     color: #e2001a !important;
     font-weight: 900 !important;
     font-size: 14px !important;
     line-height: 150%;

  }

  .detail-slova-new-content strong {

    font-weight: 900;
    font-size: 12px !important;
     line-height: 130%;
  
  }

.detail-slova-new-content {

  position: absolute;
  top: 30px;
  left:0;
  width:100%;
  line-height: 110%;
  padding-top: 25px;

}

.swiper-pagination {
  
  display: none !important;

}

.content-pages {

  .full-width {

    margin-left: 0 !important;
    margin-right: 0 !important;

  }
  
  .image {

    width: 100% !important;

    img {

      width: 100% !important;

    }

  }

}
  .textimg {

    .img {

      margin-top: 20px;

    }

  }

  .img-icons {

    margin-top: 20px;

    .icon-text {

      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon {

        margin-right: 20px;

      }

      .text {

        width: 150px;

      }

    }

  }

  .three-photo {

    margin-top: 20px;

    .photo {

      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img {

        order: 2;

      }

      .text {

        order: 1;
        margin-bottom: 30px;

      }

    }

    .button__wrapper {

      display: flex;
      justify-content: center;

    }

    .button {

      text-decoration: none;
      padding: 10px 20px;
      font-weight: bold;
      color: white;
      background: #e2001a;

    }


  }

  .img-full {

    background: none !important;

  }

  .produkt-newest {

    position: relative;
    margin-top: 20px;

    .title {

      font-size: 20px;
      font-weight: bold;
      margin-bottom: 60px;

    }

    .top {

      right: 0px !important;
      left: 70% !important;


    }

    .produkt-data-new {

      display: flex;
      justify-content: center;

    }

    .button {

      text-decoration: none;
      padding: 10px 20px;
      font-weight: bold;
      color: white;
      background: #e2001a;

    }

    .produkt-obrazek-new {

      display: flex;
      justify-content: center;

      .owl-carousel {

        display: flex;
        flex-direction: column;
        justify-content: center;

        img {

          margin-bottom: 10px;

        }

      }

    }

  }

.expander-content {

  li {

    margin-bottom: 20px;

  }

}

.content-block {

  .clanek {

    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

  }

}

.mobile-tag {

  .img-icons {

    .text_wrapper {

      display: flex;
      align-items: center;

      .text {

        display: none;

      }

    }

  }

}

#kontakty-dole {

  margin-top: 50px;

}

.social__item {

  margin-right: 20px;
  margin-top: 20px;

}

.mobile-menu-color {

  .item {

    background: #eee !important;
    color: black !important;
    border-bottom: 0.5px solid #333333 !important;

  }

}

.tlacitko-odkaz {

  background: #f00e0e none repeat scroll 0 0;
  border: medium none;
  text-decoration: none;
  box-shadow: none;
  color: #fcf9f9;
  cursor: pointer;
  font-size: 15px;
  padding: 8px 16px;
  text-shadow: none;
  transition: background-color 0.3s ease 0s;

  a {

    text-decoration: none !important;
    color: white;

  }

}


.buttons-mobile {

  margin-top: 20px !important;
  display: flex;
  justify-content: center;

  .pridat-poptavka {

    width: auto;
    border-radius: 0;
    background: url(imgs/detail-produktu.jpg) left bottom repeat-x;
    border-bottom: 2px solid #b6000d;
    color: #FFF;
    font-size: 18px;
    font-family: 'Open Sans',Arial,sans-serif;
    padding: 12px 30px 10px 30px;
    display: inline-block;
    text-decoration: none !important;
    font-weight: 700;
    margin: 0;

  }

}

@media only screen and (max-width: 500px) {

  .forms-popup-container {

      top: 8vh;
      height: 92vh;

  }
}

.obrazek-banner  {

  min-height: 370px;
  position: relative;
  margin-bottom: 10px;

}

.obrazek-pozadicko-mobile-new  {

  img {

    min-height: 335px;

  }

}

#upoutavky_cisla_mobile {

  position:absolute; width: 100%; bottom: 0; z-index:4; display: flex; justify-content: center;

  a {
    
    background:url("imgs/prepinac.png") no-repeat; width:22px; height:22px; display:block; float:left; margin-right:5px; font-size:0px; color:#FFFFFF; z-index:5;
 
    &.on {

      background:url("imgs/prepinac_hover.png") no-repeat; width:22px; height:22px; display:block; 
    }

  }

}


.price-info {

  border-top: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  padding: 8px 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

}

.price-info-produkt {

  border: none;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;

}

.h3 {

    padding: 6px 0;

    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 20px;
      color: black;

    }  

}

.price-wrap {

  display: flex;
  flex-direction: row;
  gap: 16px;

}

.price-text {

  margin-top: 3px;

}

.price-info .col {

  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;

}

.price-info .pridat-poptavka {

  float: none;
  min-width: 140px;
  padding: 14px 0;
  margin-top: 0;

  &:first-child {

    background: #6E6E6E;
    border-bottom: 2px solid #515151;

    &:hover {

      background: #515151;

    }

  }

}

.price-info .price {

  color: #E2001A;
  font-weight: bold;
  font-size: 24px;

}

.price-info .price-puvodni {

  color: #6E6E6E;
  font-weight: bold;
  font-size: 20px;
  text-decoration: line-through;

}

.column {

  flex-direction: column;

}

.price-info .sleva {

  background: #000;
  color: #FFF;
  font-weight: bold;
  font-size: 14px;
  padding: 7px 8px;

}

.price-info .pridat-poptavka {

    padding-left: 16px !important;
    padding-right: 16px !important;

  
}
.detail-produktu {background:url("imgs/detail-produktu.jpg") left bottom repeat-x; border-bottom:2px solid #b6000d; color:#FFF; font-size:18px; font-family:'Open Sans', Arial, sans-serif; padding:10px 30px 8px 30px; display:inline-block; text-decoration:none; margin-top:10px; font-weight:700;}
.detail-produktu:hover {background:#b6000d; color:#FFF;}
.produkt-data {
  margin-bottom: 20px !important;
}
.produkt-data a {
  text-decoration: none !important;
}

.produkt {
  position: relative;
}

.produkt-data.mobile {
  
}


