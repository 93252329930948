/**
* @version 1.2
* @changelog
*    1.1 opraven problém s zmenšováním loga na Androidu
*	 1.2 ikonka košíku
*/

#navbar {
	background-color: @navbarBackground;
	color: @navbarIconColor;	
	transition: box-shadow 0.4s, border 0.4s, opacity 0.4s;
	z-index: 999 !important;
	/*border: solid 1px transparent;	*/

	.navbar-icon {
		position: absolute;
		padding: (10px + (@navbarHeight - 40px) / 2) 10px 10px 10px; // nutno změnit i u shrinked a bigger verze
		width: (@navbarIconSize + 10px);
		height: (@navbarHeight - 20px - (@navbarHeight - 40px) / 2 );  // nutno změnit i u shrinked a bigger verze
		text-align: center;
		top: 0px;
		font-size: @navbarIconSize;
		transition: background-color 0.2s, color 0.1s, opacity 0.5s, padding 0.4s, height 0.4s, font-size 0.4s, visibility 0.4s;


		&:active {
			background-color: @navbarIconPressedBackground;
			color: @navbarIconPressedColor;
		}

		&.pressed {
			background: @searchBackground;
			color: @navbarIconColor;
		}
	}

	.icon-1 {
		left: 0px;
	}

	.icon-2 {
		left: 44px;
	}

	.icon-3 {
		right: 0px;
	}

	.icon-4 {
		right: 44px;
		display: none;
	}

	.logo {
		text-align: center;
		padding: @navbarLogoPadding 0;
		height: 100%;
		box-sizing: border-box;
		transition: padding 0.4s;

		a {
			display: block;
			height: 100%;
			width: auto;
		}

		img {
			width: auto;
			max-height: 100%;
			max-width: 100%;
		}
	}

	.navbar-icon-invisible {
		visibility: hidden;
		opacity: 0;
	}

	.navbar-icon-badge {
		position: absolute;
		right: 1px;
		top: 10px;
		top: calc(50% - 16px);
		color: white;
		display: block;		
		background-color: #D90000;
		padding: 3px 6px;
		font-size: 12px;
		line-height: 12px;
		border-radius: 20px;

		&:empty {
			display: none;			
		}
	}

}

#toggler-search {
	.alternative-icon {
		display: none; 
	}
	.primary-icon {
		display: inline-block; 
	}	
}

body.with-search-visible {

	#toggler-search {
		.alternative-icon {
			display: inline-block; 
		}
		.primary-icon {
			display: none; 
		}	
	}	
	#navbar {
		box-shadow: none;
		border-bottom: none;	
	}
}

body.with-search-visible.shrinkable-navbar.scrolled {
	#navbar {
		box-shadow: none;
		border-bottom: none;	
	}	
}

body.showing-offcanvas {
	#navbar {
		opacity: 0;
	}
}

body.shrinkable-navbar {

	#navbar {
		transition: box-shadow 0.4s, border 0.4s, opacity 0.4s, height 0.4s;
	}

	&.scrolled #navbar {
		box-shadow: @navbarShadow;
		border-bottom: @navbarBorder;
		height: @navbarShrinkedHeight;

 		.logo {
			padding: @navbarShrinkedLogoPadding 0;
		}
		.navbar-icon {
			padding: (10px + (@navbarShrinkedHeight - 40px) / 2) 10px 10px 10px;
			height: (@navbarShrinkedHeight - 20px - (@navbarShrinkedHeight - 40px) / 2 );
			font-size: @navbarShrinkedIconSize;
		}

	}
}

body.bigger-navbar {
	#navbar {
		height: @navbarBiggerHeight;

 		.logo {
			padding: @navbarBiggerLogoPadding 0;
		}
		.navbar-icon {
			// Tady by místo 8px mělo být 10 px, ale s 8px to vypadá lépe
			padding: (10px + (@navbarBiggerHeight - 40px) / 2) 10px 10px 10px;
			height: (@navbarBiggerHeight - 20px - (@navbarBiggerHeight - 40px) / 2 );
			font-size: @navbarBiggerIconSize;
		}

	}
}
