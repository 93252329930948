.articles-list {

	.price-tag {
		display: block;
		margin-top: 15px;
		text-align: center;
		color: @articlesListPriceTagColorOther;

		.main {
			color: @articlesListPriceTagColor;	
			font-weight: bold;
			font-size: 100%;
		}	

		.alt {
			font-size: 90%;
			display: block;
			margin-top: 4px;
		}

		.strike {
			font-size: 90%;
			display: block;
			margin-top: 4px;
			text-decoration: line-through;			
		}

		.note {
			font-size: 75%;
			display: block;
			margin-top: 4px;
		}


	}

	.stock-tag {

		display: block;
		margin-top: 10px;
		color: @articlesListStockTagColor;
		background-color: @articlesListStockTagBackground;
		font-size: 75%;
		text-transform: uppercase;
		border-radius: 20px;
		padding: 2px 10px;
		line-height: 13px;

		&.positive {
			color: @articlesListStockTagPositiveColor;
			background-color: @articlesListStockTagPositiveBackground;
		}

		&.negative {
			color: @articlesListStockTagNegativeColor;
			background-color: @articlesListStockTagNegativeBackground;
		}

		&.grayed {
			color: @articlesListStockTagGrayedColor;
			background-color: @articlesListStockTagGrayedBackground;
		}
	}


	.labelpos {

		@labelWidth: 80px;

		position: absolute;
		right: 0;
		top: 0;
		display: block;
		font-size: 70%;
		line-height: 17px;
		text-transform: uppercase;
		width: @labelWidth;
		height: @labelWidth;
		// border: solid 1px blue;
		overflow: hidden;

		.label {

			background-color: #777;
			color: white;
			max-width: none;
			transform: rotate(45deg) translateY((@labelWidth * 0.2));
			width: (@labelWidth * 1.7);
			text-align: center;
			
			&.with-big {
				padding-top: 2px;
				padding-bottom: 2px;
				line-height: 12px;
			}
			
			&.akce {
				color: @articlesListLabelAkceText;
				background-color: @articlesListLabelAkceBackground;
			}

			&.novinka {
				color: @articlesListLabelNovinkaText;
				background-color: @articlesListLabelNovinkaBackground;
			}

			&.top {
				color: @articlesListLabelTopText;
				background-color: @articlesListLabelTopBackground;
			}

			.big {
				display: block;		
				margin-top: 2px;
				font-size: 130%;
				font-weight: bold;							
			}
		}
	}

	.button-form {

		margin-top: 15px;
		text-align: center;		

		button {
			background-color: @articlesListButtonBackground;
			color: @articlesListButtonText;
			border: none;
			padding: 7px 15px 8px;
			font-size: 90%;
			text-transform: uppercase;
			border-radius: 20px;
			transition: all 0.3s;

			i {
				font-size: 140%;
				margin-right: 8px;
				vertical-align: bottom;				
			}

			.loading-icon {
				display: none;				
			}					
		}

		form.loading button {
			background-color: @articlesListButtonLoadingBackground;
			color: @articlesListButtonLoadingText;

			.loading-icon {
				display: inline-block;				
			}

			.std-icon {
				display: none;				
			}

		}

	}

}