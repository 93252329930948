.tbold {font-weight:bold;}
.tpodtrzeny {text-decoration:underline;}
.tkurziva {font-style:italic;}
.tvetsi {font-size:120%;}
.tmensi {font-size:80%;}
.al {text-align:left}
.aj {text-align:justify}
.ac {text-align:center}
.ar {text-align:right}
.fl {float:left;}
.fr {float:right;}
.inv {display:none;}

.obrl {float:left; margin-right:20px; margin-top:5px; margin-bottom:15px;}
.obrr {float:right; margin-left:20px; margin-top:5px; margin-bottom:15px;}

.cleaner {clear:both}
.lcleaner {clear:left}
.full {width:100%;}
.c {width:180px;}
.sloupec1 {float:none; margin-bottom: 15px;}
.sloupec2 {float:none; margin-bottom: 15px;}
.pod {padding-top:10px; clear:both;}
.nic {font-size:1px;}

.separator1 {border-bottom:solid 1px black; margin-bottom:5px; font-size:5px; height:10px;}
.separator2 {border-bottom:dotted 1px black; margin-bottom:5px; font-size:5px; height:10px;}
.separator3 {border-bottom:double 3px black; margin-bottom:5px; font-size:5px; height:10px;}
.separator4 {border-bottom:solid 1px @accentForEditableText; margin-bottom:5px; font-size:5px; height:10px;}

.vyrazny {color: @accentForEditableText;} /*Barevné zvýraznění textu*/
.vyraznyt, .vyraznyt td {background:#ddd;} /*Občas je třeba zvýraznit řádek v tabulce*/

@media (max-width: 400px) {
	.c {
		width: 120px;
	}
}

.obrl:not(.really-small) {
	float: none;
	margin: 10px 0;
	text-align: center;
}

.obrr:not(.really-small) {
	float: none;
	margin: 10px 0;
	text-align: center;
}