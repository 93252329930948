.cart-header {

	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 10px;

	.step {
		display: block;
		text-align: center;
		text-decoration: none;
	}

	.icon {
		display: block;
		margin: auto;
		margin-bottom: 6px;
		width: 24px;
		height: 19px;
		line-height: 13px;
		padding-top: 5px;
		border-radius: 50px;
		color: @orderHeadingGreyText;
		background-color: @orderHeadingGreyBackground;
		font-size: 13px;
	}

	.text {
		display: none;		
		font-size: 13px;
		line-height: normal;
	}

	.separator {
		display: block;
		
		&::before {
			content: "\f105";
			font-family: FontAwesome;
			color: @orderHeadingGreyBackground;
		}

		&.separator-past::before {
			color: @accentColor;
		}
	}

	.step.past {

		.icon {

			background-color: @accentColor;
			color: @accentText;						

			&::before {
				content: "\f00c";
				font-family: FontAwesome;
			}

			.number {
				display: none;				
			}
		}

	}

	.step.active {

		transform: scale(1.5);

		.icon {
			background-color: @accentColor;
			color: @accentText;			
		}

		.text {
			display: block;			
		}
	}

}