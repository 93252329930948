.product-single-image {

	margin-bottom: (@pagePadding);

	a {
		display: block;
		text-align: center;		
		max-height: 100%;
	}

	&.full-width {
		a {
			max-width: calc(100% + (2 * @pagePadding));			
		}

		img {
			border-radius: 0;
			box-shadow: 0;
		}
	}

	img {
		box-shadow: rgba(0, 0, 0, 0.4) 0 0 5px;
		border-radius: 3px;
	}

}

button {

	&.detail-produktu {

		margin: 0 auto;
		display: flex;

	}

}

.detail-produktu {

	background: url(imgs/detail-produktu.jpg) left bottom repeat-x;
	border-bottom: 2px solid #b6000d;
	color: #FFF;
	font-size: 18px;
	font-family: 'Open Sans', Arial, sans-serif;
	padding: 10px 30px 8px 30px;
	display: inline-block;
	text-decoration: none !important;
	margin-top: 10px;
	font-weight: 700;
	margin-bottom: 20px;

}


.ke-stazeni-new {

	.polozka-stahuj {

		a {

			display: flex;
			align-items: center;
			padding-bottom: 10px;

			img {

				margin-right: 10px;

			}

		}

	}

	.ke-stazeni-new-nadpis {

		margin-bottom: 10px;
		font-weight: bold;

	}

	.warning {

		margin-bottom: 20px;

	}

	.warning-icon {

		display: flex;
		align-items: center;
		padding-bottom: 5px;

		img {

			margin-right: 10px;

		}

	}

}

.ceny-modifikujici {

	margin-bottom: 20px;

}

.top-info {

	display: flex;
	flex-direction: column;

}

.cena-info, .vyber {

	float: none;
	margin-bottom: 20px;

	div {


		div {


			img {

				margin-bottom: 10px;
				height: 55px;

			}

		}


	}

}

.polozka-stahuj {

	overflow: hidden;

}


