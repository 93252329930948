.pagination {

	@height: 35px;
	@lineHeight: 15px;
	@greyColor: #ddd;
	margin-top: @pagePadding;

	@border: solid 1px @greyColor;


	display: flex;
	height: @height;
	line-height: @lineHeight;
	border-top: @border;
	border-bottom: @border;

	a {
		text-decoration: none;		
	}

	.arrow {
		width: (@height * 2);
		text-align: center;		

		a {
			display: block;
			padding: ((@height - (@lineHeight)) / 2) 0;
			background: @accentA;
			color: @accentText;			
		}
	}

	.links {
		flex-grow: 1;
		display: flex;

		a, span {
			display: block;
			text-align: center;
			flex-grow: 1;
			flex-basis: auto;
			padding: ((@height - (@lineHeight)) / 2) 0;
		}

		.active {
			background-color: @greyColor;
		}

	}

}

.load-next {
	padding: 15px 0;
	text-align: center;
	
	a {
		display: inline-block;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 12px;
		padding: 10px 18px;
		border-radius: 50px;
		position: relative;		
		
		background-color: @accentColor;
		color: @accentText;

		transition: all 0.3s;

		i {
			font-size: 21px;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s;
			display: block;
			position: absolute;
			left: 13px;
			top: 9px;
		}

		&.loading {
			background-color: black;
			color: white;
			padding-left: 44px; 

			i {
				opacity: 1;
				visibility: visible;
			}
		}

	}
}