.hledej-vysledky-znovu {
	display: none; 
}

.hledej-vysledky-subheading {
	color: @searchResultListFadedText;
	padding-bottom: 15px;
}

.hledej-vysledek-polozka {
	.full-width;
	padding-bottom: 8px !important;
	border-top: @searchResultListBorder;

	a {
		text-decoration: none;
		padding-top: 8px;
		display: block;
	}

	.hledej-vysledek-vpravo  {
		color: @searchResultListFadedText;
		padding-right: @pagePadding;
		padding-left: @pagePadding;		
		box-sizing: border-box;

		.tbold, .tvetsi {
			color: @pageTextColor;
			font-weight: normal;
			font-size: inherit;
		}
	}

	.hledej-vysledek-vlevo {
		padding-left: @pagePadding;
		box-sizing: border-box;
		&:empty {
			display: none; 
		}

		img {
			max-width: 100% !important;
		}
	}

	.hledej-vysledek-vlevo:empty+.hledej-vysledek-vpravo {
		float: none !important;
		width: auto !important;		
	}
}

.hledej-nic-nenalezeno {
	img {
		display: none; 

	}

	&:before {
		display: inline-block;
		font-size: 28px;
		margin-right: 14px;
		vertical-align: middle;
		font-family: FontAwesome;
		content: "\f00d";
	}
}

.hledej-vysledky-subheading-empty {
	display: none; 
}

#search-container.on-results-page {
	&.visible {
		height: auto;
	}
}