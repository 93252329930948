/*
* @version 1.1
* @changelog
*	1.1 zabaleni zpet
*
*/

.expandable-box {

	margin-bottom: (@pagePadding * 0.75);
	position: relative;
	padding-bottom: (@expandableBoxExpanderTextSize*3 + 1px);

	&.enabled-expanding {
		overflow: hidden;

		.expander-content {
			overflow: hidden;
			max-height: 100%;
		}
	}

	.expander {
		position: absolute;
		text-align: center;
		bottom: 0px;
		left: 0;
		right: 0;
		text-align: center;

		border-top: @expandableBoxExpanderBorder;

		a {
			font-size: @expandableBoxExpanderTextSize;
			color: @expandableBoxExpanderText;
			text-decoration: none;				
			line-height: @expandableBoxExpanderTextSize;
			height: @expandableBoxExpanderTextSize;
			padding: (@expandableBoxExpanderTextSize) @pagePadding;
			display: block;
			background: linear-gradient(fade(@expandableBoxExpanderShadowColor, 50%) 0%, fade(@expandableBoxExpanderShadowColor, 0%) 4px);

			&::before {
				content: "\f103";
				margin-right: 7px;
				font-family: FontAwesome;
			}				
		}

	}		

	.unexpander {

		border-top: none;

		a {
			background: none;
		}
		

		a::before {
			content: "\f102";
		}
	}	

}