#contacts-pane {
	background: @contactsBackground;
	min-height: calc(100% + 1px); // To prevent scrolling
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	
	
	&.with-sticky-footer {
		padding-bottom: 54px;
	}

	h2 {
		font-size: 22px;
		font-weight: normal;
		padding: (@contactsHeaderHeight * 0.2) 30px;
		margin: 0;
		line-height: (@contactsHeaderHeight * 0.6);
		text-align: center;

		&.with-return-arrows {
			padding-left: 45px;
			padding-right: 45px;
		}
	}

	h2:not(:first-child) {
		padding-top: 20px;
		padding-bottom: 10px;
	}

	h3 {
		font-size: 18px;
		font-weight: normal;
		margin: 0;
		line-height: 24px;
		text-align: center;		
		padding-top: 20px;
		padding-bottom: 10px;

	}

	.block {
		position: relative;
		padding: 15px 20px 15px 65px;

		&.accented-block {
			background-color: @accentColor;
			color: @accentText;
		}

		&.separator-block {
			border-bottom: @contactsSeparator;
		}

		&.block-without-padding {
			padding-bottom: 0;			
		}

		&.block-with-link {
			padding: 0;
		}

		a.block-link {
			display: block;
			padding: 15px 20px 15px 65px;
		}

		&.block-without-padding {
			a.block-link {
				padding-bottom: 0;
			}
		}		

		.icon {
			position: absolute;
			left: 23px;
			top: 16px;
			font-size: 18px;
		}

		.icon-right {
			position: absolute;
			right: 20px;
			top: 18px;
			font-size: 18px;
		}

		.icon-dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			transform: translate(7px, 6px);
		}

		.icon-positive {
			background-color: @contactsPositiveDot;
		}
		.icon-negative {
			background-color: @contactsNegativeDot;
		}
		.icon-neutral {
			background-color: @contactsNeutralDot;
		}

		.expander {
			display: block;
			padding-top: 6px;

			color: #888;
			font-size: 85%;

			&:after {
				margin-left: 5px;
				font-family: FontAwesome;
				content: "\f107";
			}

			&.expanded {
				height: 0;
				overflow: hidden;
			}
		}

		.expandable {
			height: 0;
			overflow: hidden;

			&.visible {
				height: auto;
			}
		}

		table {
			td:not(:last-child) {
				padding-right: 12px;
			}
		}

	}

	.stretcher {
		flex-grow: 1;
	}

	.social-links {
		background-color: @contactsSocialLinksBackground;
		color: @contactsSocialLinksColor;
		display: flex;

		&.sticky {
			position: fixed;
			bottom: 0;
			left: 15%;
			width: 85%;
		}


		a, .item {
			display: block;
			text-align: center;
			height: 40px;
			padding-top: 14px;
			flex-grow: 1;

			i {
				font-size: 28px;
			}
		}

	}
}