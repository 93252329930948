#search-container {
	position: fixed;
	left: 0;
	top: @navbarHeight;
	width: 100%;
	height: 0;
	overflow: hidden;
	transition: height 0.3s;

	#search-bar {
		background: @searchBackground;
		height: 0px;
		overflow: hidden;
		visibility: hidden;
		transition: visibility 0.3s, height 0.3s, padding 0.3s;
		box-sizing: border-box;
		position: relative;
		padding: 0 @searchPadding;

		input, button {
			height: (@navbarSearchHeight - @searchPadding * 2);
			box-sizing: border-box;		
			padding: 0;
			margin: 0;
			border: none;
			vertical-align: middle;
			border-radius: 2px;
		}

		input {

			color: @searchFieldText;
			background: @searchField;
			padding-left: 30px;
			font-size: 13px;
			width: 100%;
			&::placeholder {
				opacity: 0.6;
				color: @searchFieldText;
			}
		}


		.icon {
			position: absolute;
			display: none;
			left: (@searchPadding + 6px);
			top: 10px;
			top: calc(50% - 7px);
			color: @searchFieldText;
		}

	}

	#search-list {
		opacity: 0;
		background: @navbarBackground;
		visibility: hidden;
		height: 70%;
		height: calc(100% - (@navbarSearchHeight+@navbarHeight));
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		transition: visibility 0.3s, opacity 0.3s;

		li {
			border-bottom: @searchSuggestionListBorder;
			list-style-type: none;

			a {
				display: block;
				padding: @searchFieldPaddingTop 15px;
				color: @searchFieldText;
				transition: background 0.2s, color 0.1s;

				&:active {
					background: @searchSuggestionListActiveBackground;
					color: @searchSuggestionListActiveColor;
				}

				&:hover {
					text-decoration: underline;
				}
			}

		}

		&:empty {
			display: none; 
		}
	}	

	&.visible {
	
		height: 70%;
		height: calc(100% - @navbarSearchHeight);

		#search-bar {
			visibility: visible;
			height: @navbarSearchHeight;
			padding: @searchPadding;

			.icon {
				display: block;
			}
		}
	}

	&.visible.with-list {
		#search-list {
			visibility: visible;
			opacity: 1;
		}
	}	

}

body.bigger-navbar #search-container {
	top: @navbarBiggerHeight;
}

body.shrinkable-navbar.scrolled #search-container {
	top: @navbarShrinkedHeight;
}


body.bigger-navbar #search-container #search-list {
	height: 60%;
	height: calc(100% - @navbarSearchHeight);
}

body.shrinkable-navbar.scrolled #search-container #search-list {
	height: 60%;
	height: calc(100% - @navbarSearchHeight);
}