/**
* @version 1.1
* @changelog
*    1.1 vy�e�en probl�m s moc velk�mi obr�zky
*/

.product-gallery {

	display: flex;
	align-items: flex-start;
	margin-top: @pagePadding;
	width: 100%;

	&.columnized-2 {
		.column {
			width: 45%;
			width: calc(50% - @pagePadding);
		}
	}

	&.columnized-3 {
		.column {
			width: 30%;
			width: calc(33.3% - (@pagePadding * 2));
		}
	}

	&.columnized-4 {
		.column {
			width: 20%;
			width: calc(25% - (@pagePadding * 3));
		}
	}

	.column {
		margin-right: @pagePadding;
		// flex-basis: 0; // krav� ve FF
		flex-grow: 1;
		flex-shrink: 1;		

		&:last-child {
			margin-right: 0;
		}
	}

	&.with-borders {
		.full-width();
		padding: @pagePadding;
		background-color: @productsGalleryBackground;

		.product-gallery-item {
			background-color: @productsGalleryItemBackground;
			border: @productsGalleryBorder;
			padding-bottom: @productsGalleryBorderPadding;
		}

		.name, .subtitle {
			padding: 0 @productsGalleryBorderPadding;		
		}

	}

}

a.product-gallery-item {

	text-decoration: none;	
	margin-bottom: @pagePadding;
	display: block;	
	position: relative;	

	.image {
		margin-bottom: (@productsGalleryRowMargin - 3px);		
		text-align: center;

		&.normalised-height {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		img {
			max-height: 100%;
		}
	}

	.image-in {
		position: relative;				
	}

	.name {
		color: @productsGalleryNameColor;
		font-size: @productsGalleryNameSize;
		margin-bottom: (@productsGalleryRowMargin - 3px);
	}


	.subtitle {
		color: @articlesSubtitleColor;
		font-size: @productsGallerySubtitleSize;
		line-height: (@productsGallerySubtitleSize * 1.5);
	}

	a {
		display: block;
		text-decoration: none;		
		position: relative;	
		margin-bottom: @pagePadding;
	}

	.price {
		margin-top: @productsGalleryRowMargin;
		text-align: center;		
		color: @productsGalleryPriceColor;
		background-color: @productsGalleryPriceBackground;
		font-size: @productsGalleryPriceSize;
		line-height: 100%;
		margin-bottom: (@productsGalleryRowMargin - 3px);

		&.floating {
			margin: 0;
			position: absolute;
			right: 0px;
			bottom: 10px;
			padding: 4px 8px;
			border-radius: 10px 0 0 10px;
		}
	}	

	.stock {
		text-align: center;
		font-size: 12px;
		line-height: 100%;

		&.positive {
			color: @articlesListStockTagPositiveColor;
			background-color: @articlesListStockTagPositiveBackground;
		}

		&.negative {
			color: @articlesListStockTagNegativeColor;
			background-color: @articlesListStockTagNegativeBackground;
		}

		&.grayed {
			color: @articlesListStockTagGrayedColor;
			background-color: @articlesListStockTagGrayedBackground;
		}

	}

	.price-stock {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.price {
			margin: 0;
			text-align: right;			
			order: 2;
		}

		.stock {
			margin: 0;
			text-align: left;
			order: 1;
		}
	}

	.label {
		position: absolute;	
		left: 0;
		top: 4px;
		font-size: 11px;
		text-transform: uppercase;
		padding: 3px 6px;
		line-height: 100%;
		background-color: white;			

		&.novinka {
			color: @articlesListLabelNovinkaText;
			background-color: @articlesListLabelNovinkaBackground;
		}

		&.akce {
			color: @articlesListLabelAkceText;
			background-color: @articlesListLabelAkceBackground;
		}

		&.top {
			color: @articlesListLabelTopText;
			background-color: @articlesListLabelTopBackground;
		}
	}

}

.product-gallery:not(.with-borders) a.product-gallery-item .image {
	padding-left: (@pagePadding / 2);
	padding-right: (@pagePadding / 2);
}
