.big-select {

	padding: (@pagePadding/3) @pagePadding;
	background-color: black;
	position: relative;	
	margin-top: @pagePadding;	


	select {
		color: white;
		background-color: black;
		text-transform: uppercase;
		border: none;
		border-radius: 0;
		font-size: 12px;
		-moz-appearance: none;		
		-webkit-appearance: none;		
		-ms-appearance: none;		
		appearance: none;		
		width: 95%;
	}

	.icon {
		display: block;		
		position: absolute;
		right: (@pagePadding/2);
		top: 5px;
		top: calc(50% - 8px);
		color: white;
	}
}