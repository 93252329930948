.products-swiper {
	
	margin-bottom: (@pagePadding * 0.75);

	.heading {
		font: normal @fontWeightLight 21px  opensans, Arial, sans-serif;
		margin: 20px 0 10px 0;
	}

	.swiper-container-horizontal .swiper-pagination {
		bottom: 3px;
	}

	.swiper-slide {
		max-width: 50%;
		max-width: calc(50% - (@pagePadding / 2));
		margin-right: @pagePadding;

		&:last-child {
			margin-right: 0;
		}
	}

	@media (min-width: 400px) {
		.swiper-slide {
			max-width: 33.3%;
			max-width: calc(33.3% - (@pagePadding * 2 / 3));
		}		
	}

	@media (min-width: 580px) {
		.swiper-slide {
			max-width: 25%;
			max-width: calc(25% - (@pagePadding * 3 / 4));
		}		
	}

}