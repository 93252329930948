/**
* @version 1.1
* @changelog
*   Opraveno slajdovani pro android do verze 4.2
*/

body.android-2, body.android-4-0, body.android-4-1, body.android-4-2 {

	background: none;

	&.showing-offcanvas {

		#body-main {
			display: none; 
			visibility: visible;
		}

		&.showing-offcanvas-left #body-left, &.showing-offcanvas-right #body-right {

			height: auto;
			width: auto;
			position: static;
			left: auto;
			top: auto;
			visibility: visible;
			opacity: 1;	
			transition: none;

		}
	}

}