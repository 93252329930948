.params-table {

	line-height: normal;
	margin-bottom: (@pagePadding * 0.75);

	.heading, .subheading {
		font: normal @fontWeightLight 18px  opensans, Arial, sans-serif;
		margin: 10px 0 10px 0;
	}

	.heading, .subheading:first-child {
		margin-top: 0;
	}

	.subheading {
		margin-top: @pagePadding;
	}

	.param {
		border-top: solid 1px #D5D5D5;

		&:first-child {
			border-top: none;
		}

		&:not(.custom-row) {
			display: flex;			
		}
	}

	.subheading+.param, .heading+.param {
		border-top: none;
	}

	.name, .value, .custom-row {
		font-size: 14px;
		padding: 5px 0;
	}

	.value {
		text-align: right;
		flex-grow: 1;
		margin-left: 10px;
	}

	.param.accent {
		.value {
			color: @accentColor;
		}
	}

	.param.with-bg {
		background: #D5D5D5;
		.full-width();
		padding-left: @pagePadding;
		padding-right: @pagePadding;
	}

	.param.small {
		.name, .value, .custom-row {
			font-size: 12px;
			padding: 3px 0;
		}		
	}


}