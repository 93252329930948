/**
@version 1.1
@changelog
	1.1 podpora pro víceřádkovou, nezkracovanou a s výrazným tlačítkem
*/

.breadcrumbs {

	display: flex;
	color: @breadcrumbsColor;
	font-size: 12px;
	position: relative;
	margin-top: -10px;

	&.overflow-allowed {
		display: block;
		padding-bottom: 5px;
		line-height: 14px;

		.back {
			display: inline-block;			
		}

		.item {
			padding-top: 0;
			margin-bottom: 5px;
		}
	}

	.back {
		display: block;
	}

	.back.accented-back {
		background-color: @accentColor;
		color: @accentText;
		font-size: 11px;
		text-transform: uppercase;
		padding: 4px 10px 3px 10px;
		margin-left: 10px;
		margin-right: 10px;
		border-radius: 3px;
		display: inline-block;
		

		.arrow {
			display: block;
			margin-left: -17px;
			width: 15px;
			height: 15px;
			background-color: @accentColor;
			float: left;
			transform: rotate(45deg);
			border-radius: 2px;			
		}
	}

	a {
		color: inherit;
		text-decoration: none;		
	}

	.item {
		padding-top: 5px;
		padding-bottom: 5px;
		padding-right: 7px;

		&:last-child {
			padding-right: 0;

			&:after {
				// display: none; 
			}
		}
	}

	.separator {
		&:after {
			font-family: FontAwesome;
			content: "\f105";
		}
	}

	.last {
		color: black;
		font-weight: bold;
		flex-shrink: 1;		
	}

	&:not(.overflow-allowed) .last {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;	

	}


	.mezo {

		padding-right: 0;

		.popup {
			visibility: hidden;
			opacity: 0;
			transform: translateY(15px);
			display: block;
			position: absolute;
			left: 10px;
			right: 10px;
			width: 90%;
			width: calc(100% - 20px);
			top: 40px;
			padding: 5px 0;
			background-color: @breadcrumbsPopupBackground;
			box-shadow: rgba(0, 0, 0, 0.2) 0 0 3px;
			z-index: 10;

			&, .popup {
				transition: all 0.3s;
			}

			.last-sublink {
				font-weight: bold;
				color: darken(@breadcrumbsColor, 10%);
			}

			a {
				display: block;
				padding: 5px 10px;

				&:hover, &:active {
					background-color: @breadcrumbsPopupActiveBackground;
					color: @breadcrumbsPopupActiveColor;
				}
			}

			.arrow {

				position: absolute;
				left: 25%;
				top: -13px;
				width: 25px;
				height: 13px;
				overflow: hidden;

				&:before {
					content: "";
					position: absolute;
					left: 3px;
					top: 6px;
					width: 15px;
					height: 15px;
					background-color: @breadcrumbsPopupBackground;
					transform: rotate(45deg);
					box-shadow: rgba(0, 0, 0, 0.2) 0 0 3px;
				}
			}

		}

		&.expanded .popup {
			opacity: 1;
			transform: none;
			visibility: visible;
		}

		.symbol {

			padding-right: 15px;

			&:before {
				content: "...";
			}
		}
	}

	&.omitting-current-page+h1 {
		margin-top: 10px;
	}

}