/* Tento soubor je upravov�n automaticky p�es konfigur�tor v redak�n�m syst�mu. */
/* Ru�n� editaci nedoporu�uji, pokud p�esn� nev�te, co d�l�te. */
/* Dodate�n� prom�nn� je lep�� nadefinovat ve variables-custom.less */

/* @customColors */
@accentA: #E2001A; // Akcent z�kladn�
@accentB: #474747; // Akcent vedlej��

/* @section Z�kladn� v�ci */

/* @subsection Akcenty */
@accentColor: @accentA;					// Hlavn� akcentn� barva - pozad�
@accentText: white;						// Text na akcentn�m pozad� @desc Pokud m� prvek pozad� barvou hlavn�ho akcentu, jakou barvu m� m�t text?
@accentForEditableText: @accentA;		// Akcent pro "Barevn� zv�razn�n�" text v editoru


/* @subsection Rozvr�en� panel� */
@offCanvasBodyShadow: rgba(0,0,0,0.3) 0 0 4px ; //St�n okolo hlavn�ho panelu   @type shadow
@offCanvasBodyBrightness: 130%;								// Sv�tlost po odjet� hlavn�ho panelu  @type custom
@offCanvasPaneBackground: white;							// Pozad� hlavn�ho panelu @type color
@returnIconColor: #666;										// Barva ikony pro n�vrat zp�t
@returnIconBackground: white;								// Pozad� ikony pro n�vrat zp�t
@offCanvasBodyBackground: #777 url("imgs/mobile-bg.jpg") no-repeat left top;    // Pozad� cel�ho webu @type custom @desc Zobrazuje se pod jednotliv�mi panely b�hem animace.
@offCanvasBodyBackgroundSize: 100vw 100vh;					// Rozm�ry pozad� cel�ho webu @type backgroundSize
@animationSpeed: 0.5s;										// Rychlost animace @type custom
@animationOriginStateLeft: scale(0.75) translateX(10%);		// V�choz� stav animace vlevo @type custom
@animationOriginStateRight: scale(0.75) translateX(10%);	// V�choz� stav animace napravo @type custom


/* @subsection Z�kladn� vzhled str�nky */

@pagePadding: 20px;						// Odsazen� textu od stran @type size
@pageBackground: white;					// Barva pozad�
@pageTextColor: #333;					// Barva z�kladn�ho textu
@pageTextSize: 15px;					// Velikost z�kladn�ho textu @type size


/* @section Prvky na str�nce */

/* @subsection Horn� li�ta */

@navbarBackground: white;					// Barva pozad�
@navbarIconColor: #333;						// Barva ikon
@navbarIconPressedColor: white;				// Barva ikony po stisku
@navbarIconPressedBackground: #666;			// Pozad� ikony po stisku
@navbarBorder: solid 0.5px #AAA;			// Spodn� r�me�ek @type border
@navbarShadow: rgba(0,0,0,0.3) 0 0 3px ; //St�n  @type shadow
@navbarHeight: 60px;						// V��ka  @type size
@navbarIconSize: 21px;						// Velikost ikon @type size
@navbarLogoPadding: 13px;					// Odsazen� loga naho�e a dole  @type size
@navbarShrinkedHeight: 45px;				// V��ka ve zmen�en�m stavu  @type size
@navbarShrinkedIconSize: 21px;				// Velikost ikony ve zmen�en�m stavu  @type size
@navbarShrinkedLogoPadding: 10px;			// Odsazen� loga ve zmen�en�m stavu  @type size
@navbarBiggerHeight: 60px;					// V��ka ve velk�m stavu  @type size
@navbarBiggerIconSize: 21px;				// Velikost ikony ve velk�m stavu  @type size
@navbarBiggerLogoPadding: 13px;				// Odsazen� loga ve velk�m stavu  @type size

/* @subsection Vyhled�va� */

@navbarSearchHeight: 50px;							// V��ka  @type size
@searchBackground: #e9e9e9;							// Barva pozad� a r�me�ku
@searchPadding: 10px;								// Odsazen� od okraj� @type size
@searchField: white;								// Barva pol��ka
@searchFieldText: #666;								// Text v pol��ku
@searchSuggestionListBorder: solid 0.5px #eee;		// ��ry v na�ept�va�i @type border
@searchSuggestionListActiveBackground: black;		// Pozad� aktivn�ho ��dku v na�ept�va�i
@searchSuggestionListActiveColor: white;			// Text aktivn�ho ��dku v na�ept�va�i
@searchFieldPaddingTop: 10px;						// Odsazen� naho�e @type size
@searchResultListBorder: solid 0.5px #ddd;			// R�me�ek ve v�sledc�ch @type border
@searchResultListFadedText: #666;					// Barva za�edl�ho textu ne v�sledc�ch

/* @subsection Panel s kontakty */

@contactsBackground: #eee;						// Pozad�
@contactsHeaderHeight: 50px;					// V��ka z�hlav� @type size
@contactsSeparator: solid 0.7px #ccc;			// Odd�lova� @type border
@contactsPositiveDot: @accentA;					// Pozitivn� te�ka
@contactsNegativeDot: #D90000;					// Negativn� te�ka
@contactsNeutralDot: #777;						// Neutr�ln� te�ka
@contactsSocialLinksBackground: #181818;		// Soci�ln� ikony - pozad�
@contactsSocialLinksColor: white;				// Soci�ln� ikony - ikona


/* @subsection P�ep�na� na desktop */
@switcherSmallColor: #888;				// Barva mal�ho p�ep�na�e
@switcherBigBackground: @accentA;		// Barva velk�ho p�ep�na�e - pozad�
@switcherBigColor: white;				// Barva velk�ho p�ep�na�e - text


/* @subsection Drobe�kovka */
@breadcrumbsColor: #888;						// Text drobe�kovky
@breadcrumbsPopupBackground: #fff;				// Pozad� vyskakovac� bubl�nky
@breadcrumbsPopupActiveBackground: @accentA;	// Pozad� aktivn� polo�ky v bublince
@breadcrumbsPopupActiveColor: white;			// Barva aktivn� polo�ky v bublince


/* @section Navigace */

/* @subsection Z�kladn� v�ci */

@menuBackground: #eee;							// Pozad�
@menuHeaderHeight: 50px;						// V��ka z�hlav� @type size
@menuVertPadding: 10px;							// Odsazen� z�hlav� @type size

/* @subsection Rozbalovac� menu */

@menuLevel1Background: #1c1c1c;					// �rove� 1 - pozad�
@menuLevel1Color: white;						// �rove� 1 - text
@menuLevel1Separator: solid 0.5px black;		// �rove� 1 - r�me�ek @type border
@menuLevel1ExpandedBackground: #1c1c1c;			// �rove� 1 - pozad� rozbalen� polo�ky
@menuLevel1ExpandedColor: white;				// �rove� 1 - text rozbalen� polo�ky
@menuLevel1ActiveBackground: @accentA;			// �rove� 1 - pozad� aktivn� polo�ky
@menuLevel1ActiveColor: white;					// �rove� 1 - text aktivn� polo�ky
@menuLevel1LeftPadding: 50px;					// �rove� 1 - odsazen� zleva @type size

@menuLevel2Background: white;					// �rove� 2 - pozad�
@menuLevel2Color: black;						// �rove� 2 - text
@menuLevel2Separator: solid 0.5px #e5e5e5;		// �rove� 2 - r�me�ek @type border
@menuLevel2ExpandedBackground: #eee;			// �rove� 2 - pozad� rozbalen� polo�ky
@menuLevel2ExpandedColor: black;				// �rove� 2 - text rozbalen� polo�ky
@menuLevel2ActiveBackground: @accentA;			// �rove� 2 - pozad� aktivn� polo�ky
@menuLevel2ActiveColor: white;					// �rove� 2 - text aktivn� polo�ky
@menuLevel2LeftPadding: 50px;					// �rove� 2 - odsazen� zleva @type size

@menuLevel3Background: white;					// �rove� 3 - pozad�
@menuLevel3Color: @accentA;						// �rove� 3 - text
@menuLevel3Separator: solid 0.5px #e5e5e5;		// �rove� 3 - r�me�ek @type border
@menuLevel3ExpandedBackground: #eee;			// �rove� 3 - pozad� rozbalen� polo�ky
@menuLevel3ExpandedColor: #20b054;				// �rove� 3 - text rozbalen� polo�ky
@menuLevel3SideBorder: none;					// �rove� 3 - ��ra nalevo @type border
@menuLevel3ActiveBackground: @accentA;			// �rove� 3 - pozad� aktivn� polo�ky
@menuLevel3ActiveColor: white;					// �rove� 3 - text aktivn� polo�ky
@menuLevel3LeftPadding: 70px;					// �rove� 3 - odsazen� zleva @type size

@menuLevel4Background: white;					// �rove� 4 - pozad�
@menuLevel4Color: @accentA;						// �rove� 4 - text
@menuLevel4Separator: solid 0.5px #e5e5e5;		// �rove� 4 - r�me�ek @type border
@menuLevel4ExpandedBackground: white;			// �rove� 4 - pozad� rozbalen� polo�ky
@menuLevel4ExpandedColor: @accentA;				// �rove� 4 - text rozbalen� polo�ky
@menuLevel4SideBorder: none;					// �rove� 4 - ��ra nalevo @type border
@menuLevel4ActiveBackground: @accentA;			// �rove� 4 - pozad� aktivn� polo�ky
@menuLevel4ActiveColor: white;					// �rove� 4 - text aktivn� polo�ky
@menuLevel4LeftPadding: 90px;					// �rove� 4 - odsazen� zleva @type size

/* @subsection P�ep�na� jazyk� */

@languagesHeight: 54px;							// P�ep�na� jazyk� - v��ka @type size
@languagesBackground: white;					// P�ep�na� jazyk� - pozad�
@languagesColor: black;							// P�ep�na� jazyk� - barva
@languagesActiveBorder: solid 5px #1c57ff; //P�ep�na� jazyk� - aktivn� - r�me�ek  @type border
@languagesActiveBackground: #f4f4f4;			// P�ep�na� jazyk� - aktivn� - pozad�

/* @subsection Dodate�n� odkazy */

@menuLinksColor: #777;				// Barva odkazu
@menuActiveColor: black;			// Barva aktivn�ho odkazu
@menuActiveBackground: #ccc;		// Pozad� aktivn�ho odkazu

/* @subsection Textov� bloky */

@menuTextBlockColor: #777;			// Barva textu

/* @section Prvky v obsahu */

/* @subsection Produktovka */

@linkGridBorder: solid 0.5px #AAA;							// R�me�ek @type border
@linkGridBackground: #333;									// Pozad�
@linkGridColor: white;										// Barva textu
@linkGridActiveBackground: @accentB;						// Aktivn� polo�ka - pozad�
@linkGridActiveColor: white;								// Aktivn� polo�ka - text
@linkGridActiveBorderColor: #111;							// Aktivn� polo�ka - barva r�me�ku
@linkGridLabelBackground: rgba(255, 255, 255, 0.85);		// Popiska - pozad�
@linkGridLabelColor: black;									// Popiska - text

/* @subsection V�pis s �irok�mi obr�zky */

@wideMenuLabelBackground: rgba(0, 0, 0, 0.4);				// Popiska - pozad�
@wideMenuLabelColor: white;									// Popiska - text
@wideMenuSeparator: none;									// Odd�lova� @type border

/* @subsection V�pis textov�ch polo�ek */
@articlesListBackground: white;					// Pozad�
@articlesListBorder: solid 0.5px #ccc;			// R�me�ek  @type border
@articlesListImageSize: 80px;					// Velikost obr�zku / data @type size
@articlesTitleColor: #333;						// Titulek - barva
@articlesTitleSize: 18px;						// Titulek - velikost textu @type size
@articlesSubtitleColor: #999;					// Podtitulek - barva
@articlesSubtitleSize: 14px;					// Podtitulek - velikost textu @type size
@articlesDateBackground: @accentA;				// Datum - pozad�
@articlesDateColor: white;						// Datum - barva textu
@articlesActiveBackground: @accentB;			// Aktivn� datum - barva
@articlesActiveColor: white;					// Aktivn� datum - barva textu

/* @subsection Rozbalovac� blok s textem */
@expandableBoxExpanderBorder: solid 1px #ccc;	// Okraj nad rozbalov�tkem @type border
@expandableBoxExpanderText: #999;				// Barva textu v rozbalov�tku
@expandableBoxExpanderTextSize: 14px;			// Velikost textu v rozbalov�tku @type size
@expandableBoxExpanderShadowColor: #ccc;		// Z�kladn� barva st�ne�ku na rozbalov�tku



/* @section E-shop */

/* @subsection Z�kladn� */
@includeEshop: 1; //P�ipojit stylov�n� e-shopu?        @type bool 

/* @subsection V�pis produkt� */
@articlesListPriceTagColor: black;				// �t�tek s cenou, hlavn� cenovka - barva textu
@articlesListPriceTagColorOther: #999;			// �t�tek s cenou, ostatn� informace - barva textu

@articlesListStockTagColor: black;					// �t�tek s dostupnost� - z�kladn� barva textu
@articlesListStockTagBackground: white;				// �t�tek s dostupnost� - z�kladn� barva pozad�
@articlesListStockTagPositiveColor: #22C064;			// �t�tek s dostupnost� - pozitivn�, text
@articlesListStockTagPositiveBackground: white;	// �t�tek s dostupnost� - pozitivn�, pozad�
@articlesListStockTagNegativeColor: #DD4843;			// �t�tek s dostupnost� - negativn�, text
@articlesListStockTagNegativeBackground: white;	// �t�tek s dostupnost� - negativn�, pozad�
@articlesListStockTagGrayedColor: #666;			// �t�tek s dostupnost� - nev�razn�, text
@articlesListStockTagGrayedBackground: white;		// �t�tek s dostupnost� - nev�razn�, pozad�
@articlesListLabelAkceText: white;					// �t�tek v rohu - akce, text
@articlesListLabelAkceBackground: #F4962C;			// �t�tek v rohu - akce, pozad�
@articlesListLabelTopText: white;					// �t�tek v rohu - top, text
@articlesListLabelTopBackground: #DD4843;			// �t�tek v rohu - top, pozad�
@articlesListLabelNovinkaText: white;				// �t�tek v rohu - novinka, text
@articlesListLabelNovinkaBackground: #22C064;		// �t�tek v rohu - novinka, pozad�

@articlesListButtonText: white;						// Nakupovac� tla��tko - text
@articlesListButtonBackground: #3646FD;				// Nakupovac� tla��tko - pozad�
@articlesListButtonLoadingText: white;				// Nakupovac� tla��tko pracuje - text
@articlesListButtonLoadingBackground: black;		// Nakupovac� tla��tko pracuje - pozad�

/* @subsection V�pis produkt� - s velk�mi obr�zky */
@productsGalleryNameColor: #333;		// N�zev produktu - text
@productsGalleryNameSize: 14px;			// N�zev produktu - velikost @type size
@productsGallerySubtitleColor: #666;	// Popiska produktu - text
@productsGallerySubtitleSize: 12px;		// Popiska produktu - velikost @type size
@productsGalleryPriceColor: black;		// Cena produktu - text
@productsGalleryPriceSize: 14px;		// Cena produktu - velikost @type size
@productsGalleryPriceBackground: white;	// Cena produktu - pozad�
@productsGalleryRowMargin: 6px;			// Odsazen� jednotliv�ch ��dk� od sebe @type size

@productsGalleryBorderPadding: 12px;	// Odsazen� od r�me�ku (je-li) @type size
@productsGalleryBorder: none;			// R�me�ek @type border
@productsGalleryItemBackground: white;	// Barva pozad� jednotliv�ch karti�ek
@productsGalleryBackground: #f3f3f3;	// Barva pozad� okolo karti�ek

/* @subsection Detail produktu */
@prodDetailBuyButtonBg: @accentA;	// Barva nakupovac�ho tla��tka
@prodDetailBuyButtonColor: white;	// Barva textu v nakupovac�ho tla��tka
@prodDetailMainPriceColor: @accentA;  // Barva hlavn� cenovky
@prodDetailMainPriceSize: 20px;  		// Velikost hlavn� cenovky @type size
@prodDetailPriceLabelColor: #666;		// Barva popisek cenovek
@prodDetailPriceLabelFontSize: 12px;	// Velikost popisek cenovek @type size
@prodDetailPriceValueColor: #888;		// Barva hodnoty dodate�n�ch cenovek
@prodDetailPriceValueFontSize: 12px;	// Velikost hodnoty cenovek @type size
@prodDetailCountInputBg: #e5e5e5;		// Pol��ko pro zad�n� po�tu - pozad�
@prodDetailCountInputFocusBg: #ccc;		// Pol��ko pro zad�n� po�tu - pozad� b�hem editace
@prodDetailCountInputBorder: none;		// Pol��ko pro zad�n� po�tu - okraj   @type border
@prodDetailCountSpinner: #999;			// �udl�ky pro zm�nu po�tu
@prodDetailPositiveColor: #007700;		// Pozitivn� barva (je skladem...)
@prodDetailNegativeColor: #770000;		// Negativn� barva (nen� skladem...)
@prodDetailGreyColor: #777777;			// Za�edl� barva (nev�me...)