.buybox {

	@partsMargin: (@pagePadding * 0.75);
	@buttonHeight: 40px;

	margin-bottom: @partsMargin;

	.buy-row {
		display: flex;
		align-items: center;
		margin-bottom: @partsMargin;

		.count {
			flex-grow: 0;
			margin-right: 25px;

			.input-count {
				width: 50px;
				height: 25px;
				border-radius: 50px;
				text-align: center;
				font-size: 15px;
				box-sizing: border-box;
				border: @prodDetailCountInputBorder;
				background: @prodDetailCountInputBg;

				&:focus {
					background-color: @prodDetailCountInputFocusBg;
				}
			}

			button {
				font-size: 14px;
				background-color: transparent;
				color: @prodDetailCountSpinner;
				border: none;
				width: 25px;
				height: 25px;
				box-sizing: border-box;
			}
		}

		.button-part {
			flex-grow: 1;

			button {
				width: 100%;
				height: @buttonHeight;
				text-transform: uppercase;
				border-radius: 50px;
				color: @prodDetailBuyButtonColor;
				background-color: @prodDetailBuyButtonBg;
				transition: all 0.3s;

				i {
					margin-right: 10px;
					font-size: 25px;
					vertical-align: middle;	
					line-height: normal;				
				}

				i.cart {
					display: inline-block;					
				}

				i.working {
					display: none;					
				}

				.text {
					vertical-align: middle;
					display: inline-block;					
				}

				&.working {
					background-color: black;
					color: white;

					i.cart {
						display: none;
					}

					i.working {
						display: inline-block;
					}					
				}

				.sticked-only {
					display: none;					
				}
			}
		}

		.delivery {
			margin-right: (@pagePadding * 1.5);
		}
	}


	.delivery {
		text-transform: uppercase;
		font-size: 85%;

		&.positive {
			color: @prodDetailPositiveColor;
		}

		&.negative {
			color: @prodDetailNegativeColor;
		}

		&.grayed {
			color: @prodDetailGreyColor;
		}		
	}

	.delivery-standalone {
		font-size: inherit;
	}

	.prices {
		margin-bottom: @partsMargin;

		&:empty {
			display: none;			
		}
	}

	.price-pair {
		display: flex;			
		height: 27px;
		align-items: center;

		.name {
			color: @prodDetailPriceLabelColor;
			font-size: @prodDetailPriceLabelFontSize;
		}

		.value {
			text-align: right;			
			margin-right: 10px;
			flex-grow: 1;
		} 

		.value:not(.delivery) {
			color: @prodDetailPriceValueColor;
			font-size: @prodDetailPriceValueFontSize;										
		}

		&.main-price {
			.value {
				font-size: @prodDetailMainPriceSize;
				color: @prodDetailMainPriceColor;
				font-weight: bold;				
			}
		}

		&.accented {
			.value {
				color: @prodDetailMainPriceColor;
			}
		}

		&.striked {
			.value {
				text-decoration: line-through;
			}
		}

		&.greyed {
			.value {
				color: @prodDetailGreyColor;
			}
		}
	}

	.config {
		margin-bottom: @partsMargin;
	}

	.buy-row.sticky-button-row {

		&.sticked {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			transform: translateY(55px);
			z-index: 20;
			margin-bottom: 0;
			background: white;

			.button-part button {
				border-radius: 0;

				.sticked-only {
					display: inline;
					vertical-align: middle;					
				}
			}

			.count:not(.sticky-count) {
				display: none;				
			}

			.count {
				height: @buttonHeight;
				margin-right: 0;

				input, button {
					height: @buttonHeight;
				}

				button {
					background-color: @prodDetailCountInputBg;
					color: @pageTextColor;
				}

				input {
					width: 40px;
					border-radius: 0;
				}
			}

			&.sticked-visible {
				transition: transform 0.4s;
				transform: none;
			}			
		}

	}

}